import { FC } from "react";
import arrowsUp from "src/assets/images/icon/arrowsUp.svg";
import styles from "./style.module.scss";

interface ButtonProps {
  onClick: () => void;
}

const GoTopButton: FC<ButtonProps> = (props) => {
  return (
    <div className={styles.goTopButton} onClick={props.onClick}>
      <img className={styles.arrowsUpIcon} src={arrowsUp} alt="" />
      <div className={styles.goTop}>TOPへ戻る</div>
    </div>
  );
};
export default GoTopButton;
