import styles from "./style.module.scss";
import logoImage from "src/assets/images/logo/logo.svg";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useHeader } from "src/hooks/useHeader";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useApexMenu } from "src/hooks/useApexMenu";
import { useApexRoom } from "src/hooks/useApexRoom";
import { covertDateString, isEmpty } from "src/utils/tools/common";
import CustomCodeTextBox from "../Base/List/CustomCodeTextBox";
import { useApexUser } from "src/hooks/useApexUser";

interface Props {
  webSocket: WebSocket | undefined;
}

const RoomHeader: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const headers = useHeader((state) => state.headers);
  const headerType = useHeader((state) => state.headerType);
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const hideDetails = useMediaQuery({ query: "(max-width: 1099px)" });
  const dropDownRef = useRef<HTMLDivElement>(null);
  const isOpenMenu = useApexMenu((state) => state.isOpenMenu);
  const isSlideIn = useApexMenu((state) => state.isSlideIn);
  const closeMenu = useApexMenu((state) => state.closeMenu);
  const openMenu = useApexMenu((state) => state.openMenu);
  const roomInfo = useApexRoom((state) => state.roomInfo);
  const isScheduling = isEmpty(roomInfo.eventDateTime);
  const setIsShowSchedule = useApexRoom((state) => state.setIsShowSchedule);
  const setIsShowInfo = useApexRoom((state) => state.setIsShowInfo);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;

      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(eventNode) &&
        eventNode.firstChild?.textContent !== "INFORMATION" &&
        eventNode.firstChild?.textContent !== "arrow_drop_down"
      ) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.roomHeaderContainer}>
      <div className={styles.roomLogoContainer}>
        <img
          className={styles.roomLogo}
          src={logoImage}
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className={styles.roomInfo}>
        <div className={styles.roomTitle}>{roomInfo.title}</div>
        <div className={styles.roomDetail}>
          <span className={styles.eventDateTimeItem}>開催日：</span>
          <span className={styles.eventDateTimeInfo}>
            {isScheduling ? "調整中" : covertDateString(roomInfo.eventDateTime)}
          </span>
          {isScheduling && (
            <span
              className={styles.clickSchedule}
              onClick={() => {
                setIsShowSchedule(true);
              }}
            >
              ※参加可能な日を回答してください
            </span>
          )}
          <span className={styles.separator}>/</span>
          <span className={styles.ownerNameItem}>オーナー：</span>
          <span className={styles.ownerName}>{roomInfo.ownerName}</span>
        </div>
      </div>
      <div className={styles.subMenu}>
        <CustomCodeTextBox webSocket={props.webSocket} />
        <div
          className={styles.buttonBackGround}
          onClick={() => {
            setIsShowSchedule(true);
          }}
        >
          <span
            className={classnames(
              "material-symbols-rounded",
              styles.calendarButton
            )}
          >
            calendar_month
          </span>
        </div>
        <div
          className={styles.buttonBackGround}
          onClick={() => {
            setIsShowInfo(true);
          }}
        >
          <span
            className={classnames(
              "material-symbols-rounded",
              styles.roomInfoButton
            )}
          >
            info
          </span>
        </div>
      </div>
    </div>
  );
};

export default RoomHeader;
