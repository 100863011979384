import classnames from "classnames";
import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import BasicButton from "src/components/Base/Button/BasicButton";
import { v4 as uuidv4 } from "uuid";
import { useApexUser } from "src/hooks/useApexUser";
import { useApexRoom } from "src/hooks/useApexRoom";
import { createSearchParams, useNavigate } from "react-router-dom";
import InputTextSmall from "src/components/Base/List/TextBoxSmall";
import { isEmpty } from "src/utils/tools/common";
import error from "src/assets/json/error.json";
import { useError } from "src/hooks/useError";

interface Props {
  isPlayer: boolean;
  publicFlag: boolean;
  roomID?: string;
  roomType: string;
  onClose: () => void;
  byURL: boolean;
}

const JoinRoomModal: React.FunctionComponent<Props> = (props) => {
  const [isPlayer, setIsPlayer] = useState(props.isPlayer);
  const [roomPassword, setRoomPassword] = useState("");
  const [ownerPassword, setOwnerPassword] = useState("");
  const [roomID, setRoomID] = useState(props.roomID ? props.roomID : "");
  const [name, setName] = useState("");
  const [joinDisable, setJoinDisable] = useState(true);
  const joinRoomError = useError((state) => state.joinRoomError);
  const setJoinRoomError = useError((state) => state.setJoinRoomError);
  const setUUID = useApexUser((state) => state.setUUID);
  const setUserName = useApexUser((state) => state.setUserName);
  const setToken = useApexUser((state) => state.setToken);
  const userInfo = useApexUser((state) => state.userInfo);
  const joinApexRoom = useApexRoom((state) => state.joinApexRoom);
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (userInfo.isAuthed) {
      setName(userInfo.userName);
    }
    return () => setJoinRoomError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (
        modalRef.current &&
        !modalRef.current.contains(eventNode) &&
        !props.byURL
      ) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  useEffect(() => {
    if (isPlayer) {
      const disabledState =
        isEmpty(name.length) ||
        (!props.publicFlag && isEmpty(roomPassword.length));
      setJoinDisable(disabledState);
    } else {
      const disabledState = isEmpty(ownerPassword.length);
      setJoinDisable(disabledState);
    }
  }, [isPlayer, name, ownerPassword, props.publicFlag, roomPassword]);

  return (
    <div
      className={classnames(
        styles.modalWrapper,
        props.byURL && styles.accessByURL
      )}
    >
      <div
        className={classnames(
          styles.modalMask,
          props.byURL && styles.accessByURL
        )}
      />
      <div className={styles.modalContainer} ref={modalRef}>
        <span
          className={classnames("material-symbols-rounded", styles.closeButton)}
          onClick={props.onClose}
        >
          close
        </span>
        <div className={styles.title}>ルーム入室</div>
        {isPlayer ? (
          <div className={styles.subTitle}>
            <span>オーナーとして入室する場合は専用の入り口、または</span>
            <span
              className={styles.subText}
              onClick={() => {
                setIsPlayer(false);
              }}
            >
              こちら
            </span>
            <span>から入室してください</span>
          </div>
        ) : (
          <div className={styles.subTitle}>
            ルームに対して各種権限を持つオーナーとして入室します
          </div>
        )}
        <div className={styles.contents}>
          {isPlayer && !props.publicFlag && (
            <>
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>ルームパスワード</div>
                  <div className={styles.required}>必須</div>
                </div>
                <div className={styles.inputStyle}>
                  <InputTextSmall
                    value={roomPassword}
                    onChange={(event) => {
                      setRoomPassword(event.target.value.trim());
                    }}
                    isRequired={true}
                    isError={!isEmpty(joinRoomError)}
                    hideError={true}
                  />
                  <div className={styles.joinError}>{joinRoomError}</div>
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}
          {isPlayer && (
            <>
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>ユーザーネーム</div>
                  <div className={styles.required}>必須</div>
                </div>
                <div className={styles.inputStyle}>
                  <InputTextSmall
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value.trim());
                    }}
                    isRequired={true}
                    hideError={true}
                  />
                  <div className={styles.inputDescription}>
                    ※できるだけゲーム内と同じ名前でご参加ください
                  </div>
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}
          {!isPlayer && (
            <>
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>ルームID</div>
                  <div className={styles.required}>必須</div>
                </div>
                <div className={styles.inputStyle}>
                  <InputTextSmall
                    value={roomID}
                    onChange={(event) => {
                      setRoomID(event.target.value.trim());
                    }}
                    isRequired={true}
                  />
                </div>
              </div>
              <hr className={styles.separation} />
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>
                    ルームパスワード
                    <div className={styles.nameDescription}>※オーナー用</div>
                  </div>
                  <div className={styles.required}>必須</div>
                </div>
                <div className={styles.inputStyle}>
                  <InputTextSmall
                    value={ownerPassword}
                    onChange={(event) => {
                      setOwnerPassword(event.target.value.trim());
                    }}
                    isRequired={true}
                  />
                  <div className={styles.joinError}>{joinRoomError}</div>
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}
        </div>
        <div className={styles.joinButton}>
          <BasicButton
            isDisabled={joinDisable}
            visual={"secondary"}
            text={"ルームへ入室"}
            onClick={() => {
              const uuid = uuidv4();
              setUUID(uuid);
              if (isPlayer) {
                setUserName(name);
              }
              setLoadingMessage("Loading...");
              joinApexRoom(
                roomID,
                uuid,
                name,
                isPlayer,
                isPlayer ? roomPassword : ownerPassword
              ).then((response) => {
                if (response.token) {
                  const params = { roomID: roomID };
                  setToken(response.token);
                  if (props.byURL) {
                    navigate({
                      pathname: "/apexRoom/" + props.roomType,
                      search: `?${createSearchParams(params)}`,
                    });
                  } else {
                    window.open(
                      "/apexRoom/" +
                        props.roomType +
                        "?" +
                        createSearchParams(params),
                      "_blank"
                    );
                    props.onClose();
                  }
                } else {
                  if (response.status) {
                    const errorStatus =
                      error[
                        response.status.toLocaleString() as keyof typeof error
                      ];
                    const errorMessage =
                      errorStatus[response.data as keyof typeof errorStatus];
                    setJoinRoomError(errorMessage);
                  }
                }
                setLoadingMessage("");
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinRoomModal;
