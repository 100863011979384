import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import arrowsLeft from "src/assets/images/icon/arrowsLeft.svg";
import logoImage from "src/assets/images/logo/logo.svg";
import logoImage2 from "src/assets/images/logo/logoCharacter.svg";

type menuType = "terms" | "privacyPolicy" | null;
interface HeaderProps {
  menu: menuType;
}

const textData = {
  terms: {
    ja: "利用規約",
    en: "TERMS OF SERVICE",
  },
  privacyPolicy: {
    ja: "プライバシーポリシー",
    en: "PRIVACY POLICY",
  },
};

const InfoHeader: React.FunctionComponent<HeaderProps> = ({ menu }) => {
  const navigate = useNavigate();
  const handleClickGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={styles.InfoHeaderWrapper}>
      <div className={styles.headerContainer}>
        <div className={styles.backButtonWrapper}>
          <div onClick={handleClickGoBack} className={styles.link}>
            <img
              className={styles.arrowsLeft}
              src={arrowsLeft}
              alt="トップページへ移動"
            />
            <div className={styles.moveToBackText}>前の画面へ戻る</div>
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.title}>{menu ? textData[menu].en : ""}</div>
          <div className={styles.subTitle}>{menu ? textData[menu].ja : ""}</div>
        </div>
      </div>
    </div>
  );
};

export default InfoHeader;
