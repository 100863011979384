import styles from "./style.module.scss";
import MockArticles from "src/assets/json/mockArticles.json";
import { useParams } from "react-router-dom";
import NewsHeader from "src/components/NewsHeader";
import classnames from "classnames";
import GoTopButton from "../Base/Button/GoTopButton";
import { useRef } from "react";
import Footer from "../ApexContent/Footer";

const NewsDetail: React.FunctionComponent = () => {
  const { id } = useParams();
  const article = MockArticles.find((obj) => obj.id === Number(id));
  const topRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={styles.tools}>
        <GoTopButton
          onClick={() => {
            topRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      </div>
      <div className={styles.newsDetailContentWrapper} ref={topRef}>
        {id && article ? (
          <div className={styles.newsDetailContentContainer}>
            <NewsHeader />
            <div className={styles.newsDetailContent}>
              <div className={styles.newsDetail}>
                <div className={styles.articleWrapper}>
                  <div className={styles.typeIcon}>
                    <span>{article.type.toUpperCase()}</span>
                  </div>
                  <div className={styles.newsDate}>{article.date}</div>
                </div>
                <div className={styles.newsTitle}>{article.title}</div>
                <div className={styles.imageWrapper}>
                  <img
                    className={styles.articleImage}
                    alt="記事の画像"
                    src={article.image}
                  />
                </div>
                {article.contents.map((content) => {
                  return (
                    <>
                      <div
                        className={classnames(
                          styles["contentTitle" + content.titleLevel.toString()]
                        )}
                      >
                        {content.title}
                      </div>
                      <div className={styles.contentDetail}>
                        {content.detail}
                      </div>
                      {content.image && content.image !== "" && (
                        <div className={styles.contentImageWrapper}>
                          <img
                            className={styles.contentImage}
                            alt="記事詳細の画像"
                            src={content.image}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div>{/* 存在しない記事番号の処理 */}</div>
        )}
      </div>
    </>
  );
};

export default NewsDetail;
