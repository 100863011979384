import styles from "./style.module.scss";
import { useMediaQuery } from "react-responsive";

const NewsHeader: React.FunctionComponent = () => {
  const hideDetails = useMediaQuery({ query: "(max-width: 1099px)" });

  return (
    <div className={styles.newsHeader}>
      <div className={styles.title}>NEWS</div>
      {!hideDetails && (
        <div className={styles.subTitle}>Let's play Apex Legends!</div>
      )}
    </div>
  );
};

export default NewsHeader;
