import { create } from "zustand";
import { API } from "aws-amplify";
import Cookies from "js-cookie";
import { UserType } from "src/utils/types/userTypes";
import { isEmpty } from "src/utils/tools/common";
import { Member, UserInfo } from "src/utils/types/roomTypes";

interface CheckStatus {
  isAuthed: boolean;
  userType: UserType;
  userName: string;
}

interface ApexUserState {
  uuid: string;
  setUUID: (uuid: string) => void;
  token: string;
  setToken: (token: string) => void;
  userType: UserType;
  setUserType: (userType: UserType) => void;
  userName: string;
  setUserName: (userName: string) => void;
  isInTeam: boolean;
  teamName: string;
  showLoginModal: boolean;
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  selectNavigation: string;
  setSelectNavigation: (selectNavigation: string) => void;
  setShowLoginModal: (showLoginModal: boolean) => void;
  setTeamName: (teamName: string) => void;
  interval: NodeJS.Timer | null;
  setInterval: (interval: NodeJS.Timer) => void;
  auth: (token: string, roomID: string) => Promise<boolean>;
  startHeartBeat: (socket: WebSocket, uuid: string, roomID: string) => void;
  stopHeartBeat: () => void;
  getTeamStatus: (
    uuid: string,
    roomID: string,
    teamName: string
  ) => Promise<Array<Member>>;
  getUserInfo: (winnityToken: string, userId: string) => Promise<UserInfo>;
  deleteUser: (winnityToken: string, userId: string) => Promise<boolean>;
}

const checkToken = async (
  token: string,
  roomID: string
): Promise<CheckStatus> => {
  const request = API.post("winnityrest", "/tokenCheck", {
    headers: {
      Authorization: token,
    },
    body: {
      roomID,
    },
  });

  const response = await request.then((result) => {
    if (result) {
      return result;
    }
  });

  return response;
};

const startHeartBeat = (socket: WebSocket, uuid: string, roomID: string) => {
  return setInterval(() => {
    socket.send(
      JSON.stringify({
        action: "heartBeat",
        data: {
          uuid,
          roomID,
        },
      })
    );
  }, 120000);
};

const getTeamStatus = async (
  uuid: string,
  roomID: string,
  teamName: string
): Promise<Array<Member>> => {
  const request = API.post("winnityrest", "/getTeamStatus", {
    body: {
      uuid,
      roomID,
      teamName,
    },
  });

  const response = await request.then((result) => {
    if (result) {
      return result;
    }
  });

  return response;
};

const getUserInfo = async (
  winnityToken: string,
  userId: string
): Promise<UserInfo> => {
  const request = API.post("winnityrest", "/getUserInfo", {
    headers: {
      Authorization: winnityToken,
    },
    body: {
      userId,
    },
  });

  const response = await request.then((result) => {
    return result;
  });

  return response;
};

const deleteUser = async (
  winnityToken: string,
  userId: string
): Promise<boolean> => {
  const request = API.post("winnityrest", "/deleteUser", {
    headers: {
      Authorization: winnityToken,
    },
    body: {
      userId,
    },
  });

  const response = await request
    .then((result) => {
      return result.isDeleted;
    })
    .catch((error) => {
      return false;
    });

  return response;
};

const useApexUser = create<ApexUserState>()((set, get) => ({
  uuid: Cookies.get("uuid") || "",
  setUUID: (uuid: string) =>
    set(() => {
      Cookies.set("uuid", uuid);
      return { uuid };
    }),
  token: Cookies.get("token") || "",
  setToken: (token: string) =>
    set(() => {
      Cookies.set("token", token || "");
      return { token };
    }),
  userType: (Cookies.get("userType") as UserType) || "Player",
  setUserType: (userType: UserType) =>
    set(() => {
      Cookies.set("userType", userType || "Player");
      return { userType };
    }),
  userName: Cookies.get("userName") || "",
  setUserName: (userName: string) =>
    set(() => {
      Cookies.set("userName", userName || "");
      return { userName };
    }),
  showLoginModal: false,
  setShowLoginModal: (showLoginModal: boolean) =>
    set(() => {
      return { showLoginModal };
    }),
  userInfo: {
    isAuthed: false,
    userName: "",
    userId: "",
    avatar: "",
    steamId: "",
    steamName: "",
    steamAvatar: "",
    googleId: "",
    googleName: "",
    googleAvatar: "",
    roomHistory: [],
  },
  setUserInfo: (userInfo: UserInfo) =>
    set(() => {
      Cookies.set("userName", userInfo.userName || "");
      return { userInfo };
    }),
  selectNavigation: "user",
  setSelectNavigation: (selectNavigation: string) =>
    set(() => {
      return { selectNavigation };
    }),
  isInTeam: false,
  teamName: "",
  interval: null,
  setInterval: (interval) =>
    set((state) => {
      return { interval };
    }),
  setTeamName: (teamName) =>
    set((state) => {
      return { teamName, isInTeam: !isEmpty(teamName) };
    }),
  auth: async (token: string, roomID: string) => {
    if (isEmpty(token)) {
      return false;
    }
    const checkStatus = await checkToken(token, roomID);
    set(() => {
      Cookies.set("userType", checkStatus.userType);
      Cookies.set("userName", checkStatus.userName);
      return { userType: checkStatus.userType, userName: checkStatus.userName };
    });
    return checkStatus.isAuthed;
  },
  startHeartBeat: (socket: WebSocket, uuid: string, roomID: string) => {
    const newInterval = startHeartBeat(socket, uuid, roomID);
    set((state) => {
      return { interval: newInterval };
    });
  },
  stopHeartBeat: () => {
    const clearIntervalId = get().interval;
    clearIntervalId && clearInterval(clearIntervalId);
  },
  getTeamStatus: async (uuid: string, roomID: string, teamName: string) => {
    return getTeamStatus(uuid, roomID, teamName);
  },
  getUserInfo: async (winnityToken: string, userId: string) => {
    return getUserInfo(winnityToken, userId);
  },
  deleteUser: async (winnityToken: string, userId: string) => {
    return deleteUser(winnityToken, userId);
  },
}));

export { useApexUser };
