import { Link } from "react-router-dom";
import styles from "./style.module.scss";
import winnity_logo from "src/assets/images/2x/winnity_logo@2x.png";
import logo_x from "src/assets/images/2x/logo_x_white.png";

const Footer = () => {
  const X_URL = "https://twitter.com/WINNITY_GAMES";
  return (
    <footer className={styles.footer}>
      <img className={styles.logo} alt={"winnity_logo"} src={winnity_logo} />
      <div className={styles.linkWrapper}>
        <div className={styles.link}>
          <Link to="https://www.fenrir-inc.com/jp/">運営会社</Link>
        </div>
        <div className={styles.link}>
          <Link to="/information/privacyPolicy">プライバシーポリシー</Link>
        </div>
        <div className={styles.link}>
          <Link to="/information/terms">利用規約</Link>
        </div>
        <div className={styles.link}>
          <Link to="https://www.fenrir-inc.com/jp/inquiry/oem-form/">
            お問い合わせ
          </Link>
        </div>
        <div className={styles.link}>
          <a href="/presskit/WINNITY_Presskit.zip" download rel="noopener">
            プレスキット
          </a>
        </div>
      </div>
      <Link to={X_URL}>
        <img className={styles.logoX} alt={"logo_x"} src={logo_x} />
      </Link>
      <div className={styles.copyRight}>
        Apex Legends™ は米国の Electronic Arts 社 (以下 EA 社)
        の登録商標です。商標はそれぞれの所有者に帰属します。ゲーム素材の著作権は
        EA 社に帰属します。EA
        社は本サイト及びその内容を保証するものではなく、一切の責任を負いません。
      </div>
    </footer>
  );
};

export default Footer;
