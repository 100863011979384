import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import InfoHeader from "./InfoHeader";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import ScrollToTop from "./scrollToTop";
import Footer from "../ApexContent/Footer";

const Information: React.FunctionComponent = () => {
  const strPath = useLocation().pathname;
  const pathSegments = strPath.split("/");
  const pathName = pathSegments[pathSegments.length - 1];
  const [menuName, setMenuName] = useState<"terms" | "privacyPolicy" | null>(
    null
  );

  useEffect(() => {
    pathNameToStr(pathName);
  }, []);

  const pathNameToStr = (path: string) => {
    switch (path) {
      case "privacyPolicy":
        setMenuName("privacyPolicy");
        break;
      case "terms":
        setMenuName("terms");
        break;
      default:
      //エラーページ遷移追加
    }
  };

  return (
    <div className={styles.infoContentContainer}>
      <InfoHeader menu={menuName} />
      <ScrollToTop />
      <div className={styles.infoContent}>
        <Routes>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Information;
