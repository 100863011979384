import styles from "./style.module.scss";
import { Link, useSearchParams } from "react-router-dom";
import NewsHeader from "../NewsHeader";
import MockArticles from "src/assets/json/mockArticles.json";
import arrowsRight from "src/assets/images/icon/arrowsRight.svg";
import { useHeader } from "src/hooks/useHeader";
import { useEffect, useState } from "react";

interface Props {
  type: string;
}

interface newsType {
  id: Number;
  type: String;
  date: String;
  title: String;
  image: String;
  contents: Array<any>;
}

const News: React.FunctionComponent<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const [reverseArticle, setReverseArticle] = useState<newsType[]>([]);
  const type = searchParams.get("type");
  const setHeaderType = useHeader((state) => state.setHeaderType);

  useEffect(() => {
    setHeaderType(props.type);
  }, [props.type, setHeaderType]);

  useEffect(() => {
    if (type) {
      setReverseArticle(
        MockArticles.filter((item) => item.type === type)
          .slice()
          .reverse()
      );
    } else {
      setReverseArticle(MockArticles.slice().reverse());
    }
  }, [type]);

  return (
    <div className={styles.newsContentWrapper}>
      <div className={styles.newsContentContainer}>
        <NewsHeader />
        <div className={styles.newsContents}>
          {reverseArticle.map((articles, idx) => (
            <div className={styles.articleWrapper} key={idx}>
              <Link
                className={styles.articleLink}
                to={`/information/news/?type=${articles.type}`}
              >
                <div className={styles.typeIcon}>
                  {articles.type.toUpperCase()}
                </div>
              </Link>
              <div className={styles.newsDate}>{articles.date}</div>
              <Link
                className={styles.textLink}
                to={`/information/news/${articles.id}`}
              >
                <div className={styles.newsTitle}>{articles.title}</div>
              </Link>
              <Link to={`/information/news/${articles.id}`}>
                <div className={styles.detailButton}>
                  <img src={arrowsRight} alt="記事詳細へ" />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
