import { FC, ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

const AddRoomButton: FC<ButtonProps> = ({ ...props }) => {
  return (
    <button className={styles.tiny} onClick={props.onClick}>
      <span className={styles.materialSymbolsRounded}>add</span>
    </button>
  );
};
export default AddRoomButton;
