import { useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useApexUser } from "src/hooks/useApexUser";
import { UpdateRoomInfo } from "src/utils/types/roomTypes";
import { Tooltip } from "react-tooltip";
import { isEmpty } from "src/utils/tools/common";

interface Props {
  webSocket: WebSocket | undefined;
}

const CustomCodeTextBox: React.FunctionComponent<Props> = (props) => {
  const [masking, setMasking] = useState<boolean>(false);
  const setRoomInfo = useApexRoom((state) => state.setRoomInfo);
  const roomInfo = useApexRoom((state) => state.roomInfo);
  const updateApexRoom = useApexRoom((state) => state.updateRoomInfo);
  const userType = useApexUser((state) => state.userType);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const isOwner = userType === "Owner";

  return (
    <>
      <div className={classnames(styles.inputContainer)}>
        <input
          className={classnames(styles.inputText)}
          onChange={(e) => {
            setRoomInfo({
              ...roomInfo,
              customGameCode: e.currentTarget.value,
            });
          }}
          type={masking ? "text" : "password"}
          autoComplete={"new-password"}
          value={roomInfo.customGameCode}
          placeholder={
            isOwner ? "参加コードを入力してください" : "参加コードが未入力です"
          }
          disabled={!isOwner}
          onBlur={(e) => {
            updateApexRoom(props.webSocket, {
              ...(roomInfo as UpdateRoomInfo),
              customGameCode: e.target.value ? e.target.value : "",
            });
          }}
        />
        {!isOwner && !isEmpty(roomInfo.customGameCode) && (
          <div
            className={classnames(styles.toolTipWrapper)}
            onClick={() => {
              setShowTooltip(true);
              navigator.clipboard.writeText(roomInfo.customGameCode);
            }}
          >
            <span
              data-tooltip-id={"customCodeCopy-showTooltip"}
              data-tooltip-content={"コピーしました！"}
              data-tooltip-place="bottom"
            />
            <Tooltip
              id="customCodeCopy-showTooltip"
              className={styles.customCodeCopyToolTip}
              isOpen={showTooltip}
              afterShow={() => {
                setTimeout(() => {
                  setShowTooltip(false);
                }, 1000);
              }}
              delayHide={1000}
              noArrow
            />
          </div>
        )}
        {masking ? (
          <span
            className={classnames(
              "material-symbols-rounded",
              styles.customCodeButton
            )}
            onClick={() => {
              setMasking(false);
            }}
          >
            visibility
          </span>
        ) : (
          <span
            className={classnames(
              "material-symbols-rounded",
              styles.customCodeButton
            )}
            onClick={() => {
              setMasking(true);
            }}
          >
            visibility_off
          </span>
        )}
      </div>
    </>
  );
};

export default CustomCodeTextBox;
