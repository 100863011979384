import styles from "./style.module.scss";
import classnames from "classnames";

const NoRooms: React.FunctionComponent = () => {
  return (
    <div className={styles.noRoomsContainer}>
      <span
        className={classnames("material-symbols-rounded", styles.noRoomsIcon)}
      >
        sentiment_very_dissatisfied
      </span>
      <div className={styles.noRoomsDescription}>
        条件に当てはまるルームが見つかりませんでした
      </div>
      <div className={styles.noRoomsDescription}>
        画面右下の＋ボタンからルームを作って仲間を集めましょう！
      </div>
    </div>
  );
};

export default NoRooms;
