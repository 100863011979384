import PrimaryButton from "src/components/MyPage/Button/PrimaryButton";
import styles from "./style.module.scss";
import Modal from "src/components/Modal/Modal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useApexUser } from "src/hooks/useApexUser";

interface Props {
  onClose: () => void;
}

const SignoutModal: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const setUserInfo = useApexUser((state) => state.setUserInfo);

  return (
    <Modal className={styles.signOutModal} clickOnClose={() => props.onClose()}>
      <div className={styles.signOutModalContent}>
        <p className={styles.signOutModalTitle}>サインアウト</p>
        <p className={styles.signOutModalText}>
          サインアウトするとルームの作成など
          <br />
          一部の機能が利用できなくなります
          <br />
          <br />
          続行しますか？
        </p>
      </div>
      <div className={styles.signOutModalButton}>
        <PrimaryButton
          text="サインアウト"
          color="transparent"
          onClick={() => {
            alert("サインアウトしました");
            setUserInfo({
              isAuthed: false,
              userName: "",
              userId: "",
              avatar: "",
              steamId: "",
              steamName: "",
              steamAvatar: "",
              googleId: "",
              googleName: "",
              googleAvatar: "",
              roomHistory: [],
            });
            Cookies.remove("winnityToken", {
              path: "",
              domain: ".winnity.games",
              secure: true,
            });
            Cookies.remove("userName", {
              path: "",
              domain: ".winnity.games",
              secure: true,
            });
            Cookies.remove("userId", {
              path: "",
              domain: ".winnity.games",
              secure: true,
            });
            props.onClose();
            navigate("/roomList");
          }}
        />
        <PrimaryButton
          text="キャンセル"
          color="white"
          onClick={() => props.onClose()}
        />
      </div>
    </Modal>
  );
};

export default SignoutModal;
