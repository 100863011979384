import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";
import { useApexUser } from "src/hooks/useApexUser";
import googleIcon from "src/assets/images/icon/googleIcon.svg";
import steamIcon from "src/assets/images/icon/steamIcon.svg";
import { useNavigate } from "react-router-dom";
import SignoutModal from "../Modal/SignoutModal";
import { useApexMenu } from "src/hooks/useApexMenu";

interface Props {
  type: "header" | "menu" | "subMenu";
  logined: boolean;
}

const Account: React.FunctionComponent<Props> = (props) => {
  const showLoginModal = useApexUser((state) => state.showLoginModal);
  const setShowLoginModal = useApexUser((state) => state.setShowLoginModal);
  const setSelectNavigation = useApexUser((state) => state.setSelectNavigation);
  const [showSignoutModal, setShowSignoutModal] = useState<boolean>(false);
  const closeMenu = useApexMenu((state) => state.closeMenu);
  const userInfo = useApexUser((state) => state.userInfo);
  const steamAuthPath = process.env.REACT_APP_STEAM_AUTH_PATH || "";
  const googleAuthPath = process.env.REACT_APP_GOOGLE_AUTH_PATH || "";
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        setShowLoginModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowLoginModal]);

  if (!props.logined) {
    return (
      <>
        <div className={classnames(styles[props.type])}>
          <div
            className={classnames(styles.unSigned)}
            onClick={() => {
              setShowLoginModal(true);
            }}
          >
            {props.type === "header" ? "サインイン" : "SIGN IN WINNITY"}
          </div>
        </div>
        {showLoginModal && (
          <div className={styles.loginContainer}>
            <div className={styles.backGroundShadow} />
            <div className={styles.loginModal} ref={modalRef}>
              <div className={styles.title}>WINNITYへサインイン</div>
              <div className={styles.subTitle}>
                サインインの方法を選択してください
              </div>
              <div className={styles.socialService}>
                <div className={styles.googleLogin}>
                  <a href={googleAuthPath}>
                    <img
                      className={styles.serviceIcon}
                      src={googleIcon}
                      alt=""
                    />
                  </a>
                  <div className={styles.description}>Googleでサインイン</div>
                </div>
                <div className={styles.steamLogin}>
                  <a href={steamAuthPath}>
                    <img
                      className={styles.serviceIcon}
                      src={steamIcon}
                      alt=""
                    />
                  </a>
                  <div className={styles.description}>STEAMでサインイン</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    if (props.type === "subMenu") {
      return (
        <>
          <div className={classnames(styles.userInfoContainer)}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.signedSub
              )}
              onClick={() => {
                navigate("/myPage");
                closeMenu();
              }}
            >
              person
            </span>
            <div className={classnames(styles.userName)}>
              {userInfo.userName}
            </div>
            <div
              className={classnames(styles.userMenuItem)}
              onClick={() => {
                setSelectNavigation("user");
                navigate("/myPage");
                closeMenu();
              }}
            >
              マイプロフィール
            </div>
            <div
              className={classnames(styles.userMenuItem)}
              onClick={() => {
                setSelectNavigation("history");
                navigate("/myPage");
                closeMenu();
              }}
            >
              ルーム参加履歴
            </div>
            <div
              className={classnames(styles.userMenuItem)}
              onClick={() => {
                setSelectNavigation("setting");
                navigate("/myPage");
                closeMenu();
              }}
            >
              アカウント設定
            </div>
            <div
              className={classnames(styles.userMenuItem)}
              onClick={() => {
                setShowSignoutModal(true);
              }}
            >
              サインアウト
            </div>
          </div>
          {showSignoutModal && (
            <SignoutModal
              onClose={() => {
                setShowSignoutModal(false);
                closeMenu();
              }}
            />
          )}
        </>
      );
    }
    return (
      <div className={classnames(styles[props.type])}>
        <span
          className={classnames("material-symbols-rounded", styles.signed)}
          onClick={() => {
            navigate("/myPage");
          }}
        >
          person
        </span>
      </div>
    );
  }
};

export default Account;
