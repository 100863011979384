import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import styles from "./style.module.scss";
import Title from "./Title";
import NavigationButton from "./Button/NavigationButton";
import Setting from "./Setting";
import UserCard from "./User";
import Modal from "./Modal";
import ComingSoon from "./ComingSoon";
import PrimaryButton from "./Button/PrimaryButton";
import userIcon from "src/assets/images/icon/user.svg";
import historyIcon from "src/assets/images/icon/history.svg";
import settingIcon from "src/assets/images/icon/setting.svg";
import outIcon from "src/assets/images/icon/out.svg";
import { useApexUser } from "src/hooks/useApexUser";
import { navigationListType } from "src/utils/types/userTypes";
import SignoutModal from "../Modal/SignoutModal";

const navigationList: navigationListType = [
  {
    icon: userIcon,
    id: "user",
    text: "プロフィール",
  },
  {
    icon: historyIcon,
    id: "history",
    text: "ルーム参加履歴",
  },
  {
    icon: settingIcon,
    id: "setting",
    text: "アカウント設定",
  },
  {
    icon: outIcon,
    id: "signOut",
    text: "サインアウト",
  },
];

const MyPage: React.FunctionComponent = () => {
  const [showSignoutModal, setShowSignoutModal] = useState<boolean>(false);
  const navigationOnClick = (id: navigationListType[0]["id"]) => {
    if (id !== "signOut") {
      setSelectNavigation(id);
    } else {
      setShowSignoutModal(true);
    }
  };
  const userInfo = useApexUser((state) => state.userInfo);
  const selectNavigation = useApexUser((state) => state.selectNavigation);
  const setSelectNavigation = useApexUser((state) => state.setSelectNavigation);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Title copy="MY PAGE" subCopy="Let’s play Apex Legends!" />
        <div className={styles.contents}>
          <ul className={styles.navigation}>
            {navigationList.map((item, index) => (
              <li key={index}>
                <NavigationButton
                  icon={item.icon}
                  text={item.text}
                  onClick={() => {
                    navigationOnClick(item.id);
                  }}
                  isDisabled={
                    selectNavigation !== "signOut" &&
                    selectNavigation === item.id
                  }
                />
              </li>
            ))}
          </ul>
          <div className={styles.body}>
            {selectNavigation === "user" && (
              <UserCard userName={userInfo.userName} />
            )}
            {selectNavigation === "setting" && <Setting />}
            {selectNavigation === "history" && <ComingSoon />}
          </div>
        </div>
      </div>
      {showSignoutModal && (
        <SignoutModal
          onClose={() => {
            setShowSignoutModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MyPage;
