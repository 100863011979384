import classNames from "classnames";
import styles from "./style.module.scss";
interface TitleProps {
  copy: string;
  subCopy: string;
}
const Title = (props: TitleProps) => {
  return (
    <div className={styles.title}>
      <div className={styles.main}>{props.copy}</div>
      <div className={styles.sub}>{props.subCopy}</div>
    </div>
  );
};

export default Title;
