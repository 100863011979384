import { BrowserRouter, Routes, Route } from "react-router-dom";
import LP from "./components/LP";
import Main from "./components/Layout/Main";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<LP />} />
        <Route path={"/*"} element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
