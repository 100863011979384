import { create } from "zustand";

interface ApexMenuState {
  headers: any;
  headerType: string;
  setHeaderType: (type: string) => void;
}

const useHeader = create<ApexMenuState>()((set) => ({
  headers: {
    apexRoomList: {
      name: "ROOM LIST",
      type: "RoomList",
      uri: "/roomList",
    },
    friendsBoard: {
      name: "FRIENDS BOARD",
      type: "FriendsBoard",
      uri: "/friendsBoard",
    },
    information: {
      name: "INFORMATION",
      type: "Information",
      uri: "/information",
    },
    myPage: {
      name: "MY PAGE",
      type: "MyPage",
      uri: "/myPage",
    },
    apexRoom: {
      name: "APEX ROOM",
      type: "ApexRoom",
      uri: "/apexRoom",
    },
  },
  headerType: "",
  setHeaderType: (headerType) =>
    set((state) => {
      return {
        headerType,
      };
    }),
}));

export { useHeader };
