import RoomHeader from "src/components/RoomHeader";
import RoomBackGround from "src/components/RoomBackGround";
import styles from "./style.module.scss";
import { useApexUser } from "src/hooks/useApexUser";
import { useEffect, useRef, useState } from "react";
import Loading from "src/components/Modal/Loading";
import { useApexRoom } from "src/hooks/useApexRoom";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import { isEmpty } from "src/utils/tools/common";
import { useMediaQuery } from "react-responsive";
import { useApexChat } from "src/hooks/useApexChat";
import classnames from "classnames";
import Chat from "src/components/Chat";
import Room from "src/components/Room";
import { v4 as uuidv4 } from "uuid";
import { useHeader } from "src/hooks/useHeader";

const ApexRoom: React.FunctionComponent = () => {
  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";
  const uuid = useApexUser((state) => state.uuid);
  const setUUID = useApexUser((state) => state.setUUID);
  const webSocketRef = useRef<WebSocket>();
  const [reConnectWS, setReConnectWS] = useState(false);
  const roomInfo = useApexRoom((state) => state.roomInfo);
  const isSlideIn = useApexChat((state) => state.isSlideIn);
  const isOpenChat = useApexChat((state) => state.isOpenChat);
  const closeChat = useApexChat((state) => state.closeChat);
  const openChat = useApexChat((state) => state.openChat);
  const startHeartBeat = useApexUser((state) => state.startHeartBeat);
  const stopHeartBeat = useApexUser((state) => state.stopHeartBeat);
  const isHideChat = useMediaQuery({ query: "(max-width: 1365px)" });
  const hideChatRef = useRef<HTMLDivElement>(null);
  const setHeaderType = useHeader((state) => state.setHeaderType);
  const headers = useHeader((state) => state.headers);

  let loadImage = "";

  if (!isEmpty(roomInfo.type)) {
    const maps = apexGameInfo[roomInfo.type]?.maps;
    loadImage = maps[roomInfo.map as keyof typeof maps]?.loadImage;
  }

  useEffect(() => {
    setHeaderType(headers.apexRoom.type);
  }, [headers.apexRoom.type, setHeaderType]);

  useEffect(() => {
    let newUUID = uuid;

    if (isEmpty(newUUID)) {
      newUUID = uuidv4();
      setUUID(newUUID);
    }

    const wsURL = process.env.REACT_APP_WS_URL || "";
    const queryString = "?uuid=" + newUUID;
    const webSocket = new WebSocket(wsURL + queryString);
    webSocketRef.current = webSocket;

    startHeartBeat(webSocket, uuid, roomID);

    const onClose = (event: Event) => {
      if (event.type === "close") {
        setReConnectWS(true);
      }
    };

    webSocket.addEventListener("close", onClose);

    return () => {
      webSocket.removeEventListener("close", onClose);
      setReConnectWS(false);
      stopHeartBeat();
    };
  }, [setUUID, uuid, reConnectWS, roomID, startHeartBeat, stopHeartBeat]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Element;
      if (
        hideChatRef.current &&
        !hideChatRef.current.contains(eventNode) &&
        eventNode.id === "outOfChatArea"
      ) {
        closeChat();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeChat]);

  return (
    <>
      <RoomHeader webSocket={webSocketRef.current} />
      <RoomBackGround imageURL={loadImage} />
      <div className={styles.layOutContainer}>
        {isHideChat && (
          <div className={classnames(styles.chatContents)}>
            <div
              className={classnames(styles.chatButton)}
              onClick={() => {
                openChat();
              }}
            >
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.chatIcon
                )}
              >
                chat
              </span>
            </div>
          </div>
        )}
        {isHideChat && isOpenChat && (
          <div
            ref={hideChatRef}
            className={classnames(styles.hideChatBackGround)}
          />
        )}
        <div
          id="outOfChatArea"
          className={classnames(
            styles.leftContents,
            isSlideIn ? styles.slidein : styles.slideout,
            !isOpenChat && isHideChat && styles.hideBack
          )}
        >
          <Chat webSocket={webSocketRef.current} />
        </div>
        <div className={styles.rightContents}>
          <Room webSocket={webSocketRef.current} />
        </div>
        <Loading />
      </div>
    </>
  );
};

export default ApexRoom;
