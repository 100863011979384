import styles from "./style.module.scss";
interface NavigationButtonProps {
  icon: string;
  text: string;
  onClick: () => void;
  isDisabled: boolean;
}
const NavigationButton = (props: NavigationButtonProps) => {
  return (
    <button
      className={styles.navigation}
      onClick={props.onClick}
      disabled={props.isDisabled}
      type="button"
      title={props.text}
    >
      <img className={styles.icon} src={props.icon} alt="" />
      <div className={styles.text}>{props.text}</div>
    </button>
  );
};

export default NavigationButton;
