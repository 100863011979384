import { Route, Routes } from "react-router-dom";
import { useHeader } from "src/hooks/useHeader";
import CommonContent from "./CommonContent";
import { useApexMenu } from "src/hooks/useApexMenu";
import { useEffect, useRef, useState } from "react";
import AddRoomButton from "src/components/Base/Button/AddRoomButton";
import Card from "src/components/Card";
import { useApexRoom } from "src/hooks/useApexRoom";
import classnames from "classnames";
import NoRooms from "src/components/NoRooms";
import { GameMode, RoomInfo } from "src/utils/types/roomTypes";
import JoinRoomModal from "src/components/Modal/JoinRoomModal";
import CURoomModal from "src/components/Modal/CURoomModal";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import SearchModal from "src/components/Modal/SearchModal";
import styles from "./style.module.scss";
import MenuBackGround from "../ImgBackGround";
import FilterRoomButton from "../Base/Button/FilterRoomButton";
import GoTopButton from "../Base/Button/GoTopButton";
import Footer from "./Footer";
import gearMenu from "src/assets/images/icon/geerMenu.svg";

interface Props {
  type: string;
}

const ApexContent: React.FunctionComponent<Props> = (props) => {
  const limit = 50;
  const selectedMode = useApexMenu((state) => state.selectedMode);
  const getRooms = useApexRoom((state) => state.getRooms);
  const fetchRooms = useApexRoom((state) => state.fetchRooms);
  const fetchSearchRooms = useApexRoom((state) => state.fetchSearchRooms);
  const searchData = useApexRoom((state) => state.searchData);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowCreateRoomModal, setIsShowCreateRoomModal] =
    useState<boolean>(false);
  const [isShowSearchModal, setIsShowSearchModal] = useState<boolean>(false);
  const [isShowOwnerJoinModal, setIsShowOwnerJoinModal] =
    useState<boolean>(false);
  const [rooms, setRooms] = useState<Array<RoomInfo>>([]);
  const [isShowJoinModal, setIsShowJoinModal] = useState(false);
  const [selectedRoomID, setIsSelectedRoomID] = useState<string>("");
  const [selectedRoomType, setIsSelectedRoomType] = useState<string>("");
  const isNoRooms = rooms.length === 0;
  const searching = useApexRoom((state) => state.searching);
  const setHeaderType = useHeader((state) => state.setHeaderType);
  const [isRotate, setIsRotate] = useState(false);

  useEffect(() => {
    setHeaderType(props.type);
  }, [props.type, setHeaderType]);

  useEffect(() => {
    setIsLoading(true);
    getRooms(limit + 1).then((result) => {
      if (result.lastEvaluatedKey) {
        result.items.pop();
        const lastItem = result.items[result.items.length - 1];
        setLastEvaluatedKey({
          id: lastItem.id,
          roomType: lastItem.roomType,
          eventDateTime: lastItem.eventDateTime,
        });
      } else {
        setLastEvaluatedKey(result.lastEvaluatedKey);
      }
      setRooms(result.items);
      setIsLoading(false);
    });
  }, [getRooms, props.type, selectedMode]);

  return (
    <div className={styles.root}>
      <div className={styles.tools}>
        <div className={styles.addRoomButton}>
          <AddRoomButton
            onClick={() => {
              setIsShowCreateRoomModal(true);
            }}
          />
        </div>
        <div className={styles.filterRoomButton}>
          <FilterRoomButton
            onClick={() => {
              setIsShowSearchModal(true);
            }}
          />
        </div>
        <GoTopButton
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      </div>
      <div className={styles.apexContentContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.roomHeader}>
            <div className={styles.title}>Room List</div>
            <div className={styles.subTitle}>Let's play Apex Legends!</div>
          </div>
          <div className={styles.cardsContainer}>
            {rooms?.map((roomInfo, key) => {
              return (
                <Card
                  key={key}
                  roomInfo={roomInfo}
                  onClick={() => {
                    setIsShowJoinModal(true);
                    setIsSelectedRoomID(roomInfo.id);
                    setIsSelectedRoomType(roomInfo.type);
                  }}
                  isJoin={true}
                />
              );
            })}
            {lastEvaluatedKey && (
              <button
                className={styles.moreButton}
                type="button"
                onClick={() => {
                  setIsRotate(true);
                  if (!isLoading) {
                    setIsLoading(true);
                    if (searching) {
                      fetchSearchRooms(
                        searchData.gameMode,
                        searchData.fromDate,
                        searchData.toDate,
                        searchData.maps,
                        searchData.ownerName,
                        limit,
                        lastEvaluatedKey
                      ).then((result) => {
                        setLastEvaluatedKey(result.lastEvaluatedKey);
                        const newRooms = rooms.concat(result.items);
                        setRooms(newRooms);
                        setIsLoading(false);
                      });
                    } else {
                      fetchRooms(limit, lastEvaluatedKey).then((result) => {
                        setLastEvaluatedKey(result.lastEvaluatedKey);
                        const newRooms = rooms.concat(result.items);
                        setRooms(newRooms);
                        setIsLoading(false);
                      });
                    }
                  }
                }}
              >
                <img
                  src={gearMenu}
                  className={classnames(styles.moreButtonImage, {
                    [styles.rotate]: isRotate,
                  })}
                  onAnimationEnd={() => setIsRotate(false)}
                  alt=""
                />
                <span className={styles.moreButtonText}>さらに読み込む</span>
              </button>
            )}
          </div>
          {!isLoading && isNoRooms && <NoRooms />}
        </div>
      </div>
      {isShowJoinModal && (
        <JoinRoomModal
          isPlayer={true}
          roomID={selectedRoomID}
          roomType={selectedRoomType}
          publicFlag={true}
          onClose={() => setIsShowJoinModal(false)}
          byURL={false}
        />
      )}
      {isShowOwnerJoinModal && (
        <JoinRoomModal
          isPlayer={false}
          roomID={selectedRoomID}
          roomType={selectedRoomType}
          publicFlag={true}
          onClose={() => setIsShowOwnerJoinModal(false)}
          byURL={false}
        />
      )}
      {isShowCreateRoomModal && (
        <CURoomModal
          webSocket={undefined}
          isCreate={true}
          onClose={() => {
            setIsShowCreateRoomModal(false);
          }}
        />
      )}
      <SearchModal
        isOpen={isShowSearchModal}
        onClick={(result) => {
          setLastEvaluatedKey(result.lastEvaluatedKey);
          setRooms(result.items);
        }}
        onClose={() => {
          setIsShowSearchModal(false);
        }}
      />
      <Footer />
    </div>
  );
};

export default ApexContent;
