import Header from "src/components/Header";
import Footer from "src/components/Footer";
import ApexRoom from "src/components/Layout/ApexRoom";
import RouteGuard from "src/providers/RouteGuard";
import Information from "src/components/Information";
import MyPage from "src/components/MyPage";
import News from "src/components/News";
import NewsDetail from "src/components/NewsDetail";
import Error from "src/components/Modal/Error";
import { useHeader } from "src/hooks/useHeader";
import MenuBackGround from "src/components/ImgBackGround";
import ApexContent from "src/components/ApexContent";
import Loading from "src/components/Modal/Loading";
import FooterNews from "src/components/Footer/FooterNews";
import { useMediaQuery } from "react-responsive";
import { useApexMenu } from "src/hooks/useApexMenu";
import classnames from "classnames";
import styles from "./style.module.scss";
import { Routes, Route } from "react-router-dom";

const Main: React.FunctionComponent = () => {
  const headers = useHeader((state) => state.headers);

  return (
    <div className={classnames(styles.layOutContainer)}>
      <Header />
      <MenuBackGround />
      <Routes>
        <Route
          path={headers.apexRoomList.uri + "/*"}
          element={<ApexContent type={headers.apexRoomList.type} />}
        />
        <Route
          path={headers.information.uri + "/*"}
          element={<Information />}
        />
        <Route path={headers.myPage.uri + "/*"} element={<MyPage />} />
        <Route
          path={headers.information.uri + "/news"}
          element={<News type={headers.information.type} />}
        />
        <Route
          path={headers.information.uri + "/news/:id"}
          element={<NewsDetail />}
        />
        <Route element={<RouteGuard />}>
          <Route path={headers.apexRoom.uri + "/*"} element={<ApexRoom />} />
        </Route>
        <Route path={"/404"} element={<Error error={404} />} />
        <Route path={"/502"} element={<Error error={500} />} />
      </Routes>
      <Loading />
    </div>
  );
};

export default Main;
