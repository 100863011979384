import classNames from "classnames";
import styles from "./style.module.scss";
interface PrimaryButtonProps {
  color: "transparent" | "white" | "red";
  text: string;
  onClick: () => void;
}
const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <button
      className={classNames(styles.button, styles[props.color])}
      onClick={props.onClick}
      type="button"
      title={props.text}
    >
      {props.text}
    </button>
  );
};

export default PrimaryButton;
