import styles from "./style.module.scss";
import { useState } from "react";
import { RoomInfo } from "src/utils/types/roomTypes";
import classnames from "classnames";
import { covertDateString } from "src/utils/tools/common";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import DetailCard from "./DetailCard";

interface Props {
  roomInfo: RoomInfo;
  onClick?: () => void;
  byURL?: boolean;
  onClose?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  isJoin?: boolean;
}

const Card: React.FunctionComponent<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(props.byURL ? true : false);
  const eventDateTime = covertDateString(props.roomInfo.eventDateTime);
  const maps: any = apexGameInfo[props.roomInfo.type]?.maps;
  const gameMode = apexGameInfo[props.roomInfo.type]?.en;
  const loadImage = maps[props.roomInfo.map]?.loadImage;

  return (
    <>
      <div
        className={classnames(styles.cardContainer)}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className={styles.header}>{gameMode}</div>
        <div className={styles.banner}>
          <div className={classnames(styles.title)}>{props.roomInfo.title}</div>
          <div className={styles.ownerInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.ownerIcon,
                styles.filledStyle
              )}
            >
              account_circle
            </span>
            <span className={styles.ownerName}>{props.roomInfo.ownerName}</span>
          </div>
          <hr className={styles.line} />
          <div className={styles.eventDateInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.calendarIcon
              )}
            >
              calendar_month
            </span>
            <span className={styles.date}>
              {props.roomInfo.eventDateTime === 0
                ? "調整中"
                : eventDateTime + " START"}
            </span>
          </div>
          <div className={styles.membersInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.groups,
                styles.filledStyle
              )}
            >
              groups
            </span>
            <span className={classnames(styles.numbersInfo)}>
              {props.roomInfo.limitPlayerNumber + "人募集中"}
            </span>
          </div>
        </div>
        <div className={styles.mapImage}>
          <img className={styles.image} src={loadImage} alt="" />
        </div>
      </div>
      {isOpen && (
        <DetailCard
          roomInfo={props.roomInfo}
          isJoin={props.isJoin}
          onClose={() => {
            setIsOpen(false);
          }}
          onClick={props.onClick}
        />
      )}
    </>
  );
};

export default Card;
