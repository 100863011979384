import { FC, ButtonHTMLAttributes } from "react";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  visual: "primary" | "secondary" | "third" | "fourth" | "fifth" | "sixth";
  isDisabled: boolean;
  text?: string;
  id?: string;
}

const BasicButton: FC<ButtonProps> = ({
  isDisabled = true,
  visual = "primary",
  ...props
}) => {
  return (
    <button
      id={props.id}
      className={styles[visual]}
      disabled={isDisabled}
      data-disabled={isDisabled}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};
export default BasicButton;
