import classnames from "classnames";
import styles from "./style.module.scss";
import { useEffect, useRef, useState } from "react";
import BasicButton from "src/components/Base/Button/BasicButton";
import { useApexRoom } from "src/hooks/useApexRoom";
import InputText from "src/components/Base/List/TextBoxLarge";
import InputTextSmall from "src/components/Base/List/TextBoxSmall";
import KeywordTextBox from "src/components/Base/List/keywordTextBox";
import AddButton from "src/components/Base/Button/AddButton";
import ToggleButton from "src/components/Base/Button/ToggleButton";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import CalendarTextBox from "src/components/Base/List/CalendarTextBox";
import NumberBoxSmall from "src/components/Base/List/NumberBoxSmall";
import arrowsDown from "src/assets/images/icon/arrowsDown.svg";
import arrowsUp from "src/assets/images/icon/arrowsUp.svg";
import {
  AddRoomInfo,
  CreateRoomResult,
  GameMode,
  RoomInfo,
  UpdateRoomInfo,
} from "src/utils/types/roomTypes";
import { isEmpty } from "src/utils/tools/common";
import FinishCreateModal from "../FinishCreateModal";
import { useApexUser } from "src/hooks/useApexUser";

interface Props {
  webSocket: WebSocket | undefined;
  isCreate: boolean;
  initUpdateRoomInfo?: RoomInfo;
  onClose: () => void;
}

const CURoomModal: React.FunctionComponent<Props> = (props) => {
  const initCreateRoomInfo: AddRoomInfo = {
    id: "",
    title: "",
    keywords: [""],
    ownerName: "",
    publicFlag: true,
    eventDateTime: 0,
    gameSettings: {
      lobbyChatFlag: true,
      selfAssignFlag: true,
      aimAssistFlag: true,
      anonymousModeFlag: false,
      gameModeFlag: true,
    },
    scheduleDateTime: [
      {
        eventDateTime: Date.parse(new Date().toJSON()),
        yes: [],
        or: [],
        no: [],
      },
      {
        eventDateTime: Date.parse(new Date().toJSON()),
        yes: [],
        or: [],
        no: [],
      },
    ],
    description: "",
    type: "battleRoyale",
    limitPlayerNumber: 60,
    map: "",
  };
  const initAddRoomResult: CreateRoomResult = {
    publicFlag: true,
    type: "",
    id: "",
    ownerPassword: "",
    guestPassword: "",
  };

  const [roomInfo, setRoomInfo] = useState(
    props.isCreate ? initCreateRoomInfo : (props.initUpdateRoomInfo as RoomInfo)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [createResult, setCreateResult] = useState(initAddRoomResult);
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);
  const createApexRoom = useApexRoom((state) => state.createApexRoom);
  const updateApexRoom = useApexRoom((state) => state.updateRoomInfo);
  const userInfo = useApexUser((state) => state.userInfo);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (userInfo.isAuthed) {
      setRoomInfo({
        ...roomInfo,
        ownerName: userInfo.userName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  if (showResult) {
    return (
      <FinishCreateModal createResult={createResult} onClose={props.onClose} />
    );
  }

  return (
    <div
      className={classnames(
        styles.modalWrapper,
        !props.isCreate && styles.updateModalWrapper
      )}
    >
      <div className={classnames(styles.modalMask)} />
      <div className={styles.modalContainer} ref={modalRef}>
        <span
          className={classnames("material-symbols-rounded", styles.closeButton)}
          onClick={props.onClose}
        >
          close
        </span>
        <div className={styles.header}>
          <div className={styles.title}>
            {props.isCreate ? "ルーム新規作成" : "ルーム編集"}
          </div>
        </div>
        <div className={styles.contents}>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>募集タイトル</div>
            </div>
            <div className={styles.inputStyle}>
              <InputText
                id={"title"}
                type={"title"}
                placeholder="練習中です！楽しく遊べる方募集してます！"
                value={roomInfo.title}
                onChange={(e) => {
                  setRoomInfo({
                    ...roomInfo,
                    title: e.target.value,
                  });
                }}
                required={true}
              />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>キーワード</div>
              <div className={styles.notRequired}>任意</div>
            </div>
            <div className={styles.inputStyle}>
              {roomInfo.keywords.map((keyword, index) => {
                return (
                  <div>
                    <KeywordTextBox
                      placeholder="初心者"
                      value={keyword}
                      onChange={(e) => {
                        const newKeywords = roomInfo.keywords;
                        newKeywords[index] = e.target.value;
                        setRoomInfo({
                          ...roomInfo,
                          keywords: newKeywords,
                        });
                      }}
                      hideClose={index === 0}
                      onClick={() => {
                        const newKeywords = roomInfo.keywords;
                        newKeywords.splice(index, 1);
                        setRoomInfo({
                          ...roomInfo,
                          keywords: newKeywords,
                        });
                      }}
                      max={6}
                    />
                  </div>
                );
              })}
              {roomInfo.keywords.length < 4 && (
                <AddButton
                  size={"tiny"}
                  onClick={() => {
                    const newKeywords = roomInfo.keywords;
                    newKeywords.push("");
                    setRoomInfo({
                      ...roomInfo,
                      keywords: newKeywords,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <hr className={styles.separation} />
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>ゲームモード</div>
            </div>
            <div className={styles.inputStyle}>
              <div className={styles.itemBlock}>
                <select
                  className={styles.gameModeSelect}
                  value={roomInfo.type}
                  onChange={(event) => {
                    const key = event.target.value as GameMode;
                    setRoomInfo({
                      ...roomInfo,
                      type: key,
                      limitPlayerNumber: apexGameInfo[key].max,
                      map: "",
                    });
                  }}
                >
                  {Object.entries(apexGameInfo).map(([key, value]) => {
                    if (isEmpty(key)) {
                      return <></>;
                    }
                    return (
                      <option key={key} value={key}>
                        {value.jp}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.mapInputName}>マップ</div>
            </div>
            <div className={styles.inputStyle}>
              {Object.entries(apexGameInfo[roomInfo.type]?.maps).map(
                ([key, value]) => {
                  if (!value.loadImage) {
                    return <></>;
                  }
                  const selected = key === roomInfo.map;
                  return (
                    <div>
                      <img
                        className={classnames(styles.image, {
                          [styles.selectedImage]: selected,
                        })}
                        onClick={() => {
                          setRoomInfo({
                            ...roomInfo,
                            map: key,
                          });
                        }}
                        src={value.loadImage}
                        alt=""
                      />
                      <div className={styles.mapName}>{key}</div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className={styles.inputUnderDescription}>
            ※表示されているマップはゲーム内で選択できない場合があります
          </div>
          <hr className={styles.separation} />
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>オーナーネーム</div>
            </div>
            <div className={styles.inputStyle}>
              <div className={styles.inputOwnerName}>
                <InputTextSmall
                  value={roomInfo.ownerName}
                  onChange={(e) => {
                    setRoomInfo({
                      ...roomInfo,
                      ownerName: e.target.value,
                    });
                  }}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
          <hr className={styles.separation} />
          {props.isCreate && (
            <>
              <div className={styles.content}>
                <div className={styles.inputName}>
                  <div className={styles.name}>公開状態</div>
                </div>
                <div className={styles.inputStyle}>
                  <ToggleButton
                    checked={roomInfo.publicFlag}
                    onChange={() => {
                      setRoomInfo({
                        ...roomInfo,
                        eventDateTime: roomInfo.publicFlag
                          ? 0
                          : Date.parse(new Date().toJSON()),
                        publicFlag: !roomInfo.publicFlag,
                      });
                    }}
                    offText={"非公開"}
                    onText={"公開"}
                  />
                  <div className={styles.inputDescription}>
                    {roomInfo.publicFlag
                      ? "一覧に掲載され、誰でも参加できる状態になります※「スケジュール調整」機能はご利用できません"
                      : "一覧には掲載されず、URLとパスワードを知っている人のみが参加できる状態になります"}
                  </div>
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}

          {(props.isCreate || roomInfo.publicFlag) && (
            <>
              <div
                className={classnames(
                  styles.content,
                  !roomInfo.publicFlag && styles.disabledContent
                )}
              >
                <div className={styles.inputName}>
                  <div className={styles.name}>開催日時</div>
                </div>
                <div className={styles.inputStyle}>
                  <CalendarTextBox
                    onChange={(dateTimeValue) => {
                      if (dateTimeValue === 0) {
                        setRoomInfo({
                          ...roomInfo,
                          eventDateTime: Date.parse(new Date().toJSON()),
                        });
                      } else {
                        setRoomInfo({
                          ...roomInfo,
                          eventDateTime: dateTimeValue,
                        });
                      }
                    }}
                    value={roomInfo.eventDateTime}
                    disabled={!roomInfo.publicFlag}
                  />
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}

          {(props.isCreate || !roomInfo.publicFlag) && (
            <>
              <div
                className={classnames(
                  styles.content,
                  roomInfo.publicFlag && styles.disabledContent
                )}
              >
                <div className={styles.inputDescriptionName}>
                  <div className={styles.name}>スケジュール調整</div>
                  <div className={styles.nameDescription}>
                    ※非公開時のみ利用可能
                  </div>
                </div>
                <div className={styles.inputStyle}>
                  {roomInfo.scheduleDateTime.map((dateTime, index) => {
                    return (
                      <CalendarTextBox
                        showClose={roomInfo.scheduleDateTime.length > 1}
                        onChange={(dateTimeValue) => {
                          const newScheduleDateTime = roomInfo.scheduleDateTime;
                          if (newScheduleDateTime[index]) {
                            if (dateTimeValue === 0) {
                              if (
                                index !== 0 ||
                                newScheduleDateTime.length > 1
                              ) {
                                setRoomInfo({
                                  ...roomInfo,
                                  eventDateTime: 0,
                                  scheduleDateTime: newScheduleDateTime.filter(
                                    (_, clearIndex) => {
                                      return clearIndex !== index;
                                    }
                                  ),
                                });
                                return;
                              }
                              newScheduleDateTime[index].eventDateTime =
                                Date.parse(new Date().toJSON());
                            } else {
                              newScheduleDateTime[index].eventDateTime =
                                dateTimeValue;
                            }
                            roomInfo.scheduleDateTime[index].yes = [];
                            roomInfo.scheduleDateTime[index].or = [];
                            roomInfo.scheduleDateTime[index].no = [];
                            setRoomInfo({
                              ...roomInfo,
                              eventDateTime: 0,
                              scheduleDateTime: newScheduleDateTime,
                            });
                          }
                        }}
                        disabled={roomInfo.publicFlag}
                        value={dateTime.eventDateTime}
                      />
                    );
                  })}
                  {roomInfo.scheduleDateTime.length < 5 && (
                    <AddButton
                      size={"large"}
                      disabled={roomInfo.publicFlag}
                      onClick={() => {
                        const newScheduleDateTime = roomInfo.scheduleDateTime;
                        const now = new Date();
                        newScheduleDateTime.push({
                          eventDateTime: Date.parse(now.toJSON()),
                          yes: [],
                          or: [],
                          no: [],
                        });
                        setRoomInfo({
                          ...roomInfo,
                          scheduleDateTime: newScheduleDateTime,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <hr className={styles.separation} />
            </>
          )}
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>募集概要</div>
              <div className={styles.notRequired}>任意</div>
            </div>
            <div className={styles.inputStyle}>
              <InputText
                type={"description"}
                maxLength={60}
                placeholder="練習中です！楽しく遊べる方募集してます！"
                value={roomInfo.description}
                onChange={(e: any) => {
                  setRoomInfo({
                    ...roomInfo,
                    description: e.target.value,
                  });
                }}
                required={false}
              />
            </div>
          </div>
          <hr className={styles.separation} />
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>募集人数制限</div>
            </div>
            <div className={styles.inputStyle}>
              <div className={styles.content}>
                <NumberBoxSmall
                  value={roomInfo.limitPlayerNumber}
                  onChange={(e) => {
                    const newLimitNumber = parseInt(e.target.value);
                    setRoomInfo({
                      ...roomInfo,
                      limitPlayerNumber: newLimitNumber,
                    });
                  }}
                  max={apexGameInfo[roomInfo.type]?.max}
                  min={apexGameInfo[roomInfo.type]?.min}
                />
                <div className={styles.inputDescription}>
                  ※{apexGameInfo[roomInfo.type]?.jp}の制限人数は
                  {apexGameInfo[roomInfo.type]?.min}~
                  {apexGameInfo[roomInfo.type]?.max}です
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.separation} />
          <div className={styles.content}>
            <div className={styles.inputName}>
              <div className={styles.name}>ゲーム内設定</div>
            </div>
            <div className={styles.inputStyle}>
              <div
                className={classnames(styles.settingsWrapper, {
                  [styles.closed]: !isOpen,
                })}
              >
                {isOpen && (
                  <>
                    <div className={styles.insideContent}>
                      <div className={styles.inputName}>
                        <div className={styles.name}>チャット範囲</div>
                      </div>
                      <div className={styles.settings}>
                        <div className={styles.settingDetail}>
                          <span
                            className={classnames(
                              "material-symbols-rounded",
                              styles.filledStyle
                            )}
                            onClick={() => {
                              const newGameSettings = roomInfo.gameSettings;
                              newGameSettings.lobbyChatFlag =
                                !newGameSettings.lobbyChatFlag;
                              setRoomInfo({
                                ...roomInfo,
                                gameSettings: newGameSettings,
                              });
                            }}
                          >
                            {roomInfo.gameSettings.lobbyChatFlag
                              ? "check_box"
                              : "check_box_outline_blank"}
                          </span>
                          <span className={styles.settingName}>全員</span>
                        </div>
                        <div className={styles.settingDetail}>
                          <span
                            className={classnames(
                              "material-symbols-rounded",
                              styles.filledStyle
                            )}
                            onClick={() => {
                              const newGameSettings = roomInfo.gameSettings;
                              newGameSettings.lobbyChatFlag =
                                !newGameSettings.lobbyChatFlag;
                              setRoomInfo({
                                ...roomInfo,
                                gameSettings: newGameSettings,
                              });
                            }}
                          >
                            {roomInfo.gameSettings.lobbyChatFlag
                              ? "check_box_outline_blank"
                              : "check_box"}
                          </span>
                          <span className={styles.settingName}>管理者のみ</span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.insideContent}>
                      <div className={styles.inputName}>
                        <div className={styles.name}>チームへの自動加入</div>
                      </div>
                      <ToggleButton
                        checked={roomInfo.gameSettings.selfAssignFlag}
                        onChange={() => {
                          const newGameSettings = roomInfo.gameSettings;
                          newGameSettings.selfAssignFlag =
                            !newGameSettings.selfAssignFlag;
                          setRoomInfo({
                            ...roomInfo,
                            gameSettings: newGameSettings,
                          });
                        }}
                      />
                    </div>
                    <div className={styles.insideContent}>
                      <div className={styles.inputName}>
                        <div className={styles.name}>エイムアシスト</div>
                      </div>
                      <ToggleButton
                        checked={roomInfo.gameSettings.aimAssistFlag}
                        onChange={() => {
                          const newGameSettings = roomInfo.gameSettings;
                          newGameSettings.aimAssistFlag =
                            !newGameSettings.aimAssistFlag;
                          setRoomInfo({
                            ...roomInfo,
                            gameSettings: newGameSettings,
                          });
                        }}
                      />
                    </div>
                    <div className={styles.insideContent}>
                      <div className={styles.inputName}>
                        <div className={styles.name}>匿名モード</div>
                      </div>
                      <ToggleButton
                        checked={roomInfo.gameSettings.anonymousModeFlag}
                        onChange={() => {
                          const newGameSettings = roomInfo.gameSettings;
                          newGameSettings.anonymousModeFlag =
                            !newGameSettings.anonymousModeFlag;
                          setRoomInfo({
                            ...roomInfo,
                            gameSettings: newGameSettings,
                          });
                        }}
                      />
                    </div>
                    <div className={styles.insideContent}>
                      <div className={styles.inputName}>
                        <div className={styles.name}>
                          ゲームモードバリエーション
                        </div>
                      </div>
                      <div className={styles.settings}>
                        <div className={styles.settingDetail}>
                          <span
                            className={classnames(
                              "material-symbols-rounded",
                              styles.filledStyle
                            )}
                            onClick={() => {
                              const newGameSettings = roomInfo.gameSettings;
                              newGameSettings.gameModeFlag =
                                !newGameSettings.gameModeFlag;
                              setRoomInfo({
                                ...roomInfo,
                                gameSettings: newGameSettings,
                              });
                            }}
                          >
                            {roomInfo.gameSettings.gameModeFlag
                              ? "check_box"
                              : "check_box_outline_blank"}
                          </span>
                          <span className={styles.settingName}>通常</span>
                        </div>
                        <div className={styles.settingDetail}>
                          <span
                            className={classnames(
                              "material-symbols-rounded",
                              styles.filledStyle
                            )}
                            onClick={() => {
                              const newGameSettings = roomInfo.gameSettings;
                              newGameSettings.gameModeFlag =
                                !newGameSettings.gameModeFlag;
                              setRoomInfo({
                                ...roomInfo,
                                gameSettings: newGameSettings,
                              });
                            }}
                          >
                            {roomInfo.gameSettings.gameModeFlag
                              ? "check_box_outline_blank"
                              : "check_box"}
                          </span>
                          <span className={styles.settingName}>
                            トーナメント
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className={styles.settingSeparation} />
                    <div
                      className={styles.resetGameSettings}
                      onClick={() => {
                        const newGameSettings = initCreateRoomInfo.gameSettings;
                        setRoomInfo({
                          ...roomInfo,
                          gameSettings: newGameSettings,
                        });
                      }}
                    >
                      全てリセット
                    </div>
                  </>
                )}
                <div
                  className={styles.arrowsButton}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? (
                    <img src={arrowsUp} alt="" />
                  ) : (
                    <img src={arrowsDown} alt="" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className={styles.separation} />
        </div>
        <div className={styles.bottom}>
          <BasicButton
            isDisabled={
              isEmpty(roomInfo.title) ||
              isEmpty(roomInfo.ownerName) ||
              isEmpty(roomInfo.limitPlayerNumber) ||
              isEmpty(roomInfo.map)
            }
            visual={"secondary"}
            text={props.isCreate ? "ルームを作る" : "ルーム編集"}
            onClick={() => {
              setLoadingMessage("作成中...");
              if (props.isCreate) {
                if (roomInfo.publicFlag) {
                  roomInfo.eventDateTime =
                    roomInfo.scheduleDateTime[0].eventDateTime;
                }
                const result = createApexRoom(roomInfo);
                result.then((createResult) => {
                  setLoadingMessage("");
                  setShowResult(true);
                  setCreateResult(createResult);
                });
              } else {
                setLoadingMessage("更新中...");
                updateApexRoom(
                  props.webSocket,
                  roomInfo as UpdateRoomInfo
                ).then((result) => {
                  setLoadingMessage("");
                  props.onClose();
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CURoomModal;
