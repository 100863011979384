import styles from "./style.module.scss";
import Modal from "../Modal";
import PrimaryButton from "../Button/PrimaryButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useApexUser } from "src/hooks/useApexUser";
import { useApexRoom } from "src/hooks/useApexRoom";

interface SettingProps {}
const Setting = (props: SettingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const token = Cookies.get("winnityToken") || "";
  const userId = Cookies.get("userId") || "";
  const deleteUser = useApexUser((state) => state.deleteUser);
  const setUserInfo = useApexUser((state) => state.setUserInfo);
  const setLoadingMessage = useApexRoom((state) => state.setLoadingMessage);

  const delateOnClick = () => {
    setIsModalOpen(false);
    setLoadingMessage("アカウント削除中");
    deleteUser(token, userId).then(() => {
      setUserInfo({
        isAuthed: false,
        userName: "",
        userId: "",
        avatar: "",
        steamId: "",
        steamName: "",
        steamAvatar: "",
        googleId: "",
        googleName: "",
        googleAvatar: "",
        roomHistory: [],
      });
      Cookies.remove("winnityToken", {
        path: "",
        domain: ".winnity.games",
        secure: true,
      });
      Cookies.remove("userName", {
        path: "",
        domain: ".winnity.games",
        secure: true,
      });
      Cookies.remove("userId", {
        path: "",
        domain: ".winnity.games",
        secure: true,
      });
      setIsSubmit(true);
      setIsModalOpen(true);
      setLoadingMessage("");
    });
    //削除APIを叩く処理
  };
  const navigate = useNavigate();

  const clickOnClose = () => {
    setIsModalOpen(false);
    navigate("/roomList");
    //リダイレクト処理
  };
  return (
    <>
      {isModalOpen && (
        <Modal className={styles.modal}>
          {isSubmit ? (
            <>
              <div className={styles.content}>
                <p className={styles.title}>アカウント削除完了</p>
                <p className={styles.text}>アカウントの削除が完了しました</p>
              </div>
              <div className={styles.button}>
                <PrimaryButton
                  text="ルームリストへ"
                  color="white"
                  onClick={() => {
                    clickOnClose();
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.content}>
                <p className={styles.title}>確認</p>
                <p className={styles.text}>
                  この操作は取り消すことができません
                  <br />
                  本当にアカウントを削除しますか？
                </p>
              </div>
              <div className={styles.button}>
                <PrimaryButton
                  text="削除を実行"
                  color="red"
                  onClick={() => {
                    delateOnClick();
                  }}
                />
                <PrimaryButton
                  text="キャンセル"
                  color="white"
                  onClick={() => setIsModalOpen(false)}
                />
              </div>
            </>
          )}
        </Modal>
      )}
      <div className={styles.root}>
        <table className={styles.table}>
          <thead></thead>
          <tbody>
            <tr>
              <th>アカウント削除</th>
              <td>
                <button
                  title="アカウントを削除する"
                  className={styles.delateButton}
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsSubmit(false);
                  }}
                  type="button"
                >
                  アカウントを削除する
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
      {isModalOpen && <div className={styles.bg} />}
    </>
  );
};

export default Setting;
