import { FC, ButtonHTMLAttributes } from "react";
import classnames from "classnames";
import styles from "./style.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

const FilterRoomButton: FC<ButtonProps> = ({
  ...props
}) => {
  return (
    <button
      className={styles.tiny}
      onClick={props.onClick}
    >
      <span className={classnames(styles.materialSymbolsRounded, styles.filterIcon)}>filter_alt</span>
    </button>
  );
};
export default FilterRoomButton;
