/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "winnityrest",
            "endpoint": "https://f3h7t2pe8j.execute-api.ap-northeast-1.amazonaws.com/product",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "winnity-develop-product",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://dbatgq6vjzh57.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "User-product",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
