import styles from "./style.module.scss";
import BasicButton from "src/components/Base/Button/BasicButton";
import { RoomInfo } from "src/utils/types/roomTypes";
import classnames from "classnames";
import { covertDateString, isEmpty } from "src/utils/tools/common";
import apexGameInfo from "src/assets/json/apexGameInfo.json";
import { useApexUser } from "src/hooks/useApexUser";
import { useEffect, useRef } from "react";

interface Props {
  roomInfo: RoomInfo;
  onClick?: () => void;
  onClose?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  byUrl?: boolean;
  isJoin?: boolean;
}

const DetailCard: React.FunctionComponent<Props> = (props) => {
  const eventDateTime = covertDateString(props.roomInfo.eventDateTime);
  const maps: any = apexGameInfo[props.roomInfo.type]?.maps;
  const mapName = maps[props.roomInfo.map]?.en;
  const gameMode = apexGameInfo[props.roomInfo.type]?.en;
  const loadImage = maps[props.roomInfo.map]?.loadImage;
  const userType = useApexUser((state) => state.userType);
  const isOwner = userType === "Owner";
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;
      if (modalRef.current && !modalRef.current.contains(eventNode)) {
        props.onClose && props.onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  return (
    <>
      <div className={classnames(styles.backGroundShadow)} />
      <div className={classnames(styles.detailCardContainer)} ref={modalRef}>
        <div className={styles.header}>{gameMode}</div>
        <div className={styles.banner}>
          <div className={classnames(styles.title)}>{props.roomInfo.title}</div>
          <div className={styles.ownerInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.ownerIcon,
                styles.filledStyle
              )}
            >
              account_circle
            </span>
            <span className={styles.ownerName}>{props.roomInfo.ownerName}</span>
          </div>
          <hr className={styles.line} />
          <div className={styles.eventDateInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.calendarIcon
              )}
            >
              calendar_month
            </span>
            <span className={styles.date}>
              {props.roomInfo.eventDateTime === 0
                ? "調整中"
                : eventDateTime + " START"}
            </span>
          </div>
          <div className={styles.membersInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.groupsIcon,
                styles.filledStyle
              )}
            >
              groups
            </span>
            <span className={classnames(styles.numbersInfo)}>
              {props.roomInfo.limitPlayerNumber + "人募集中"}
            </span>
          </div>
          <div className={styles.mapInfo}>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.mapIcon,
                styles.filledStyle
              )}
            >
              map
            </span>
            <span className={styles.mapName}>{mapName}</span>
          </div>
          <hr className={styles.line} />
          <div className={styles.description}>{props.roomInfo.description}</div>
          <div className={styles.keywords}>
            {props.roomInfo.keywords
              ?.filter((keyword) => !isEmpty(keyword))
              .map((keyword, key) => {
                return (
                  <span key={key} className={styles.keyword}>
                    #{keyword}
                  </span>
                );
              })}
          </div>
          <hr className={styles.line} />
          <div className={styles.settingsInfo}>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>チャット範囲</span>
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.lobbyChatFlag
                  ? "全員"
                  : "管理者のみ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>チームへの自動加入</span>
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.selfAssignFlag ? "オン" : "オフ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>
                エイムアシストの優先設定
              </span>
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.aimAssistFlag ? "オン" : "オフ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>匿名モード</span>
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.anonymousModeFlag
                  ? "オン"
                  : "オフ"}
              </span>
            </div>
            <div className={styles.settingDetail}>
              <span className={styles.settingName}>
                ゲームモードバリエーション
              </span>
              <span
                className={classnames(
                  "material-symbols-rounded",
                  styles.rightIcon,
                  styles.filledStyle
                )}
              >
                chevron_right
              </span>
              <span className={classnames(styles.details)}>
                {props.roomInfo.gameSettings.gameModeFlag
                  ? "通常"
                  : "トーナメント"}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.mapImage}>
          <img className={styles.image} src={loadImage} alt="" />
        </div>
        {props.isJoin && (
          <div className={styles.joinButton}>
            <BasicButton
              id="joinButton"
              text="ルームへ参加"
              visual={"fifth"}
              isDisabled={false}
              onClick={(e) => {
                e.preventDefault();
                props.onClick && props.onClick();
                !props.byUrl && props.onClose && props.onClose();
              }}
            />
          </div>
        )}
        {!props.isJoin && isOwner && (
          <div className={styles.editButtons}>
            <BasicButton
              id="deleteButton"
              text="削除"
              visual={"sixth"}
              isDisabled={false}
              onClick={props.onDelete}
            />
            <BasicButton
              id="editButton"
              text="編集"
              visual={"fifth"}
              isDisabled={false}
              onClick={props.onEdit}
            />

            {/* <span
              className={classnames(
                "material-symbols-rounded",
                styles.editButton
              )}
              onClick={props.onEdit}
            >
              edit
            </span>
            <span
              className={classnames(
                "material-symbols-rounded",
                styles.editButton
              )}
              onClick={props.onDelete}
            >
              delete
            </span> */}
          </div>
        )}
      </div>
    </>
  );
};

export default DetailCard;
