import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <div className={styles.privacyPolicyWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <p>
            本ポリシーには、フェンリル株式会社（以下「当社」といいます）が、お客様（以下「利用者」という）に提供・運営する「WINNITY」（以下「本サービス」という）によって、個人情報をどのように収集し、使用するかが記載されています。本ポリシーは、本サービス等若しくは直接又は間接的に当社にご連絡をいただく全ての利用者に適用されます。
          </p>
          <p>
            他社サービス経由で当社にご連絡をいただいた場合は、受領した情報につきましては当社規定のプライバシーポリシー（
            https://www.fenrir-inc.com/jp/privacy/
            。以下「当社プライバシーポリシー」といいます）に従って管理しますが、他社サービスのご利用に関しては別途利用者と他社サービスとの間で取り交わされたプライバシーポリシーが適用されます。
          </p>
          <p>
            本サービスの利用に際しては、当社プライバシーポリシーに加え、本ポリシーが適用されます。本ポリシーの内容と当社規定のプライバシーポリシーの内容とが異なる場合は本ポリシーが優先されます。
          </p>
          <div className={styles.articleWrapper}>
            <h2>第1条 個人情報等の収集・利用目的・利用根拠</h2>
            <p>
              当社は、利用者様より以下の情報（以下、「個人情報等」といいます）をご提供いただきます。ご提供いただいた利用者様の個人情報等は、ご提供いただく際に利用者様にお知らせした利用目的、又は以下に示す利用目的の範囲内でのみ利用し、事前の同意なくこれ以外の目的には利用いたしません。なお、個人情報等は、利用する必要がなくなったときに、速やかに消去されます。また、収集されたデータは必要に応じて匿名化処理が行われ、統計データとして処理される場合があります。この場合、当社は復元不可なデータとして取り扱うように努めます。
            </p>
          </div>
          <div className={styles.articleSubWrapper}>
            <div>
              <ul className={styles.listContentWrapper}>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>収集する情報</li>
                  <p>
                    以下のような情報を収集いたします。収集する情報の詳細は、その一例を示します。
                  </p>
                  <p className={styles.defaultDescription}>
                    アクセス情報（当社が管理するサーバーへアクセスされた場合に記録されます）
                  </p>
                  <ul className={styles.listItems}>
                    <li>IPアドレス</li>
                    <li>デバイス情報（機器及びアプリケーションの識別番号）</li>
                    <li>ブラウザ情報</li>
                    <li>WEB閲覧状況（アクセス先のURL）</li>
                    <li>オペレーティングシステム、システム設定情報</li>
                    <li>タイムスタンプ情報</li>
                    <li>Cookie情報</li>
                  </ul>
                  <p className={styles.defaultDescription}>
                    お問い合わせ関連情報（当社に開示された情報を管理します）
                  </p>
                  <ul className={styles.listItems}>
                    <li>お問い合わせ内容</li>
                    <li>利用しているSNS種類</li>
                    <li>利用しているSNSアカウントの情報</li>
                    <li>会社名・部署名・団体名等</li>
                    <li>住所</li>
                    <li>氏名／おなまえ</li>
                    <li>連絡先（メールアドレス・電話番号）</li>
                  </ul>
                </div>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>収集した情報の利用目的</li>
                  <p className={styles.defaultDescription}>
                    プロモーション・営業活動
                  </p>
                  <ul className={styles.listItems}>
                    <li>当社提携サービスのご利用手続き</li>
                    <li>当社製品・サービス・イベント等のご案内</li>
                    <li>商品の提供</li>
                    <li>アンケート等への調査協力依頼</li>
                    <li>ご提供いただいたアンケート等の内容の調査・分析</li>
                    <li>サービスのご利用状況の調査・分析・制御</li>
                    <li>サービスの機能改善</li>
                  </ul>
                  <p className={styles.defaultDescription}>
                    アカウント管理・本人確認
                  </p>
                  <ul className={styles.listItems}>
                    <li>
                      外部認証サービスを利用したアカウント管理及びログイン
                    </li>
                    <li>
                      本人確認及び不正アクセス防止等のセキュリティ強化のための利用
                    </li>
                  </ul>
                  <p className={styles.defaultDescription}>お問い合わせ対応</p>
                  <ul className={styles.listItems}>
                    <li>各種お問い合わせ対応</li>
                  </ul>
                  <p className={styles.defaultDescription}>法令上の手続</p>
                  <ul className={styles.listItems}>
                    <li>
                      当社又は利用者による当社のサービス利用に関する請求、紛争についての調査・解決
                    </li>
                    <li>
                      法令上認められる場合及び国の機関又は地方公共団体への提供
                    </li>
                  </ul>
                </div>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>収集した情報の利用根拠</li>
                  <p>
                    当社は、以下のうち、1つ以上の根拠に基づいて個人情報等を利用します。
                  </p>
                  <p className={styles.defaultDescription}>同意</p>
                  <p>
                    当社は、利用者様の同意に基づき、利用者様の個人情報等を収集します。利用者様は、いつでも上記同意を撤回する権利を有します。
                  </p>
                  <p className={styles.defaultDescription}>
                    利用者様の要求への対応
                  </p>
                  <p>
                    当社は、契約の履行のために取扱が必要となる場合、契約締結前の利用者様の要求に対応して手続きを履践するために取扱が必要となる場合、利用者様の個人情報等を収集します。利用者様の要求への対応には、利用者様からのお問い合わせへの対応や採用活動を含みます。
                  </p>
                  <p className={styles.defaultDescription}>
                    法令上の義務の遵守
                  </p>
                  <p>
                    当社は、当社が従うべき法的義務を遵守するために、利用者様の個人情報等を収集します。
                  </p>
                  <p className={styles.defaultDescription}>
                    利用者様又は第三者の重要な利益の保護
                  </p>
                  <p>
                    当社は、利用者様又は第三者の重要な利益を保護するために、利用者様の個人情報等を収集します。利用者様の重要な利益には、利用者様の再入力の手間の省略、利用者様にとっての最適なコンテンツの表示、利用者様の関心に応じた広告の表示を含みます。
                  </p>
                  <p className={styles.defaultDescription}>公共の利益の保護</p>
                  <p>
                    当社は、公共の利益を保護するために必要な範囲において、利用者様の個人情報等を収集します。
                  </p>
                  <p className={styles.defaultDescription}>
                    当社又は第三者の正当な利益の追求
                  </p>
                  <p>
                    当社は、当社又は第三者の正当な利益の追求のため、利用者様の個人情報等を収集します。当社の正当な利益には、当社のサービスに関する不正行為を防止及び検出しこれに対応する場合、カスタマーサポートを提供する場合、当社のサービス向上又はセキュリティ向上のために調査・分析を行う場合及びダイレクトマーケティングを行う場合を含みます。
                  </p>
                </div>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>Cookie 等の利用について</li>
                  <p>
                    当社は、当社が運営するホームページ及び関連ホームページにおいて、Cookie
                    を利用することがあります。Cookie
                    はインターネットブラウザを介してコンピュータシステムに保存されるテキストファイルです。Cookie
                    を利用すると、ウェブページを利用したときにブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、利用者様のコンピューターに保存しておくことができます。Cookie
                    を使用することにより、当社のウェブサイト上の情報及び広告を最適化することができます。
                    利用者様は、使用するインターネットブラウザにおける設定によって、当社のウェブサイトを通じて
                    Cookie の設定を無効にすることが可能です。
                  </p>
                </div>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>本サービスの利用について</li>
                  <p>
                    本サービスを使用するにあたり、一部の機能において、当該機能の前提となる各ゲームプラットフォーム事業者のプライバシーポリシーに同意する必要があります。
                  </p>
                </div>
                <div className={styles.listWrapper}>
                  <li className={styles.listTitle}>
                    解析ツール又は第三者企業のサービスの利用について
                  </li>
                  <p>
                    本サービスは作成・運営に第三者企業のサービスを利用しております。詳細については、以下の各企業のプライバシーポリシーをご確認ください。
                  </p>
                  <div className={styles.listItemsWrapper}>
                    <ul className={styles.listItems}>
                      <li>
                        <Link
                          to="https://d1.awsstatic.com/legal/privacypolicy/AWS%20Privacy%20Notice%20-%202024-01-01_JA.pdf"
                          className={styles.underLine}
                        >
                          Amazon Web Services, Inc.
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://marketingplatform.google.com/about/analytics/terms/jp/"
                          className={styles.underLine}
                        >
                          Google アナリティクス - Terms of Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://developers.google.com/terms/api-services-user-data-policy?hl=ja"
                          className={styles.underLine}
                        >
                          Google API サービスのユーザーデータに関するポリシー
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://store.steampowered.com/privacy_agreement/japanese"
                          className={styles.underLine}
                        >
                          Steam - プライバシーポリシーに関する同意書
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div className={styles.articleWrapper}>
            <h2>第2条 その他の条項について</h2>
            <p>
              <a
                className={styles.articleLink}
                href="https://www.fenrir-inc.com/jp/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                当社プライバシーポリシー
              </a>
              に準拠します。
            </p>
          </div>
          <div>
            <ul className={styles.listContentWrapper}>
              <div className={styles.dateContent}>
                <div>附則</div>
                <div>2024年4月1日 策定</div>
                <div>第1条1項、2項及び6項に要素追加</div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
