import classNames from "classnames";
import styles from "./style.module.scss";
interface ModalProps {
  className?: string;
  clickOnClose?: () => void;
  children: React.ReactNode;
}
const Modal = (props: ModalProps) => {
  return (
    <>
      <div className={classNames(styles.body, props.className)}>
        {props.clickOnClose && (
          <button
            onClick={props.clickOnClose}
            className={styles.closeButton}
            type="button"
          />
        )}
        {props.children}
      </div>
      <div className={styles.bg} />
    </>
  );
};

export default Modal;
