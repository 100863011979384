import styles from "./style.module.scss";
import logoImage from "src/assets/images/logo/logo.svg";
import logoGroupImage from "src/assets/images/logo/logoGroup.svg";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { useHeader } from "src/hooks/useHeader";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useApexMenu } from "src/hooks/useApexMenu";
import Cookies from "js-cookie";
import Account from "../Account";
import { useApexUser } from "src/hooks/useApexUser";

const Header: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const headers = useHeader((state) => state.headers);
  const headerType = useHeader((state) => state.headerType);
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const hideDetails = useMediaQuery({ query: "(max-width: 1099px)" });
  const dropDownRef = useRef<HTMLDivElement>(null);
  const isOpenMenu = useApexMenu((state) => state.isOpenMenu);
  const isSlideIn = useApexMenu((state) => state.isSlideIn);
  const closeMenu = useApexMenu((state) => state.closeMenu);
  const openMenu = useApexMenu((state) => state.openMenu);
  const userInfo = useApexUser((state) => state.userInfo);
  const getUserInfo = useApexUser((state) => state.getUserInfo);
  const setUserInfo = useApexUser((state) => state.setUserInfo);
  const token = Cookies.get("winnityToken") || "";
  const userId = Cookies.get("userId") || "";

  useEffect(() => {
    getUserInfo(token, userId).then((result) => {
      if (result) {
        setUserInfo(result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventNode = event.target as Node;

      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(eventNode) &&
        eventNode.firstChild?.textContent !== "INFORMATION" &&
        eventNode.firstChild?.textContent !== "arrow_drop_down"
      ) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (headerType === headers.apexRoom.type) {
    return <></>;
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img
            src={logoGroupImage}
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        {hideDetails ? (
          <div
            className={classnames(
              "material-symbols-rounded",
              styles.menuButton
            )}
            onClick={() => {
              openMenu();
            }}
          >
            menu
          </div>
        ) : (
          <>
            <div className={styles.menuContainer}>
              <div
                className={classnames(
                  styles.headerItem,
                  styles.roomItem,
                  headerType === headers.apexRoomList.type && styles.selected
                )}
                onClick={() => {
                  navigate(headers.apexRoomList.uri);
                }}
              >
                {headers.apexRoomList.name}
              </div>
              <div
                className={classnames(
                  styles.headerItem,
                  styles.headerHide,
                  styles.friendsItem
                )}
              >
                {headers.friendsBoard.name}
                <div className={classnames(styles.comingSoon)}>coming soon</div>
              </div>
              <div
                className={classnames(
                  styles.headerItem,
                  styles.informationItem,
                  headerType === headers.information.type && styles.selected
                )}
                onClick={() => {
                  setOpenDropDown(!openDropDown);
                }}
              >
                {headers.information.name}
                <span
                  className={classnames(
                    styles.dropDownIcon,
                    "material-symbols-rounded"
                  )}
                >
                  arrow_drop_down
                </span>
                {openDropDown && (
                  <div
                    ref={dropDownRef}
                    className={classnames(
                      styles.dropDown,
                      styles.informationDropDown
                    )}
                  >
                    <div
                      className={classnames(styles.dropDownItem)}
                      onClick={() => {
                        navigate(headers.information.uri + "/news");
                      }}
                    >
                      ニュース
                    </div>
                    <div
                      className={classnames(
                        styles.dropDownItem,
                        styles.itemHide
                      )}
                    >
                      チュートリアル ( coming soon )
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Account type={"header"} logined={userInfo.isAuthed} />
          </>
        )}
        {isOpenMenu && (
          <>
            <div className={classnames(styles.backGroundShadow)} />
            <div
              className={classnames(
                styles.headerDetails,
                isSlideIn ? styles.slidein : styles.slideout
              )}
            >
              <div className={classnames(styles.title)}>
                <div className={styles.detailLogoContainer}>
                  <img
                    className={styles.detailLogo}
                    src={logoImage}
                    alt=""
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
                <div
                  className={classnames(
                    "material-symbols-rounded",
                    styles.closeButton
                  )}
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  close
                </div>
              </div>
              <div className={classnames(styles.content)}>
                <div
                  className={classnames(
                    styles.detailItem,
                    headerType === headers.apexRoomList.type && styles.selected
                  )}
                  onClick={() => {
                    navigate(headers.apexRoomList.uri);
                    closeMenu();
                  }}
                >
                  {headers.apexRoomList.name}
                </div>
                <div
                  className={classnames(
                    styles.detailItem,
                    styles.detailItemHide
                  )}
                >
                  {headers.friendsBoard.name}
                </div>
                <div
                  className={classnames(
                    styles.detailItem,
                    headerType === headers.information.type && styles.selected
                  )}
                  onClick={() => {
                    setOpenDropDown(!openDropDown);
                  }}
                >
                  {headers.information.name}
                  <span
                    className={classnames(
                      styles.dropDownIcon,
                      "material-symbols-rounded"
                    )}
                  >
                    arrow_drop_down
                  </span>
                </div>
                {openDropDown && (
                  <div ref={dropDownRef}>
                    <div
                      className={classnames(styles.subDetailItem)}
                      onClick={() => {
                        navigate(headers.information.uri + "/news");
                        closeMenu();
                      }}
                    >
                      ニュース
                    </div>
                    <div
                      className={classnames(
                        styles.subDetailItem,
                        styles.detailItemHide
                      )}
                    >
                      チュートリアル
                    </div>
                  </div>
                )}
              </div>
              <div className={classnames(styles.bottomContent)}>
                <Account type={"subMenu"} logined={userInfo.isAuthed} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
