import styles from "./style.module.scss";
interface SettingProps {
  userName: string;
  delateOnClick?: () => void;
}
const UserCard = (props: SettingProps) => {
  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead></thead>
        <tbody>
          <tr>
            <th>ユーザーネーム</th>
            <td>{props.userName}</td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default UserCard;
