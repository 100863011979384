import styles from "./style.module.scss";
import { useApexRoom } from "src/hooks/useApexRoom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useApexUser } from "src/hooks/useApexUser";
import JoinButton from "src/components/Base/Button/JoinButton";
import { Member } from "src/utils/types/roomTypes";
import RemoteAudioContent from "../RemoteAudioContent";
import LocalAudioContent from "../LocalAudioContent";
import classnames from "classnames";
import hark from "hark";
import { useApexVoiceChat } from "src/hooks/useApexVoiceChat";
import VoiceSettingsModal from "src/components/Modal/VoiceSettingsModal";
import Peer from "peerjs";

interface SelfChannel {
  deviceId: string;
  speaking: boolean;
  volume: number;
}
interface Props {
  webSocket: WebSocket | undefined;
}

const VoiceChat: React.FunctionComponent<Props> = (props) => {
  const psURL = process.env.REACT_APP_PEER_SERVER_URL || "";
  const psPort = process.env.REACT_APP_PEER_SERVER_PORT || "";
  const psPath = process.env.REACT_APP_PEER_SERVER_PATH || "";
  const stunURL = process.env.REACT_APP_STUN_SERVER_URL || "";
  const turnURL = process.env.REACT_APP_TURN_SERVER_URL || "";
  const port = process.env.REACT_APP_SERVER_PORT || "";
  const [voiceConnected, setVoiceConnected] = useState<boolean>(false);
  const query = new URLSearchParams(window.location.search);
  const roomID = query.get("roomID") || "";
  const peerRef = useRef<Peer>();
  const userName = useApexUser((state) => state.userName);
  const uuid = useApexUser((state) => state.uuid);
  const teamName = useApexUser((state) => state.teamName);
  const isInTeam = useApexUser((state) => state.isInTeam);

  const localVoiceStream = useApexVoiceChat((state) => state.localVoiceStream);
  const setLocalVoiceStream = useApexVoiceChat(
    (state) => state.setLocalVoiceStream
  );
  const changeInVoiceStatus = useApexVoiceChat(
    (state) => state.changeInVoiceStatus
  );
  const getTeamStatus = useApexRoom((state) => state.getTeamStatus);
  const [micMuted, setMicMuted] = useState<boolean>(false);
  const [headPhoneMuted, setHeadPhoneMuted] = useState<boolean>(false);
  const [selfChannel, setSelfChannel] = useState<SelfChannel>({
    deviceId: "",
    speaking: false,
    volume: 1,
  });

  const [chatMembers, setChatMembers] = useState<Array<Member | undefined>>([]);
  const [dataMembers, setDataMembers] = useState<Array<Member | undefined>>([]);
  const [callMember, setCallMember] = useState<Member>();
  const [dataMember, setDataMember] = useState<Member>();
  const [openVoiceSettings, setOpenVoiceSettings] = useState<boolean>(false);
  const [inputDevices, setInputDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [outputDevices, setOutputDevices] = useState<Array<MediaDeviceInfo>>(
    []
  );
  const [inputSelected, setInputSelected] = useState<string>("");
  const [outputSelected, setOutputSelected] = useState<string>("");
  const [volume, setVolume] = useState<number>(100);

  const joinVoiceChat = () => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: { deviceId: outputSelected } })
      .then(function (localStream) {
        refreshDevices();
        peerRef.current?.on("call", function (newPeerCall) {
          if (!localStream) return;
          getTeamStatus(roomID, teamName, uuid).then((onResults) => {
            const onNewChatMember = onResults.filter((onResult) => {
              return onResult.uuid === newPeerCall.peer;
            });
            if (onNewChatMember.length > 0) {
              onNewChatMember[0].mediaConnection = newPeerCall;
              setCallMember(onNewChatMember[0]);
              // setCallMember({
              //   ...onNewChatMember[0],
              //   mediaConnection: newPeerCall,
              // });
            }
            newPeerCall.answer(localStream);
          });
        });

        // peerRef.current?.on("connection", (newDataChanel) => {
        //   console.log("connection");
        //   getTeamStatus(roomID, teamName, uuid).then((onResults) => {
        //     const onNewDataMember = onResults.filter((onResult) => {
        //       return onResult.uuid === newDataChanel.peer;
        //     });
        //     if (onNewDataMember.length > 0) {
        //       onNewDataMember[0].dataConnection = newDataChanel;
        //       // setUpdateMember(onNewChatMember[0]);
        //       setDataMember({
        //         ...onNewDataMember[0],
        //         dataConnection: newDataChanel,
        //       });

        //       newDataChanel.on("data", function (data: any) {
        //         console.log(data);
        //         // const ifContain =
        //         //   voiceMembers.filter((member) => member?.peerid === data?.peerid)
        //         //     .length > 0;
        //         // if (ifContain) {
        //         //   const newVoiceMembers = voiceMembers.map((member: any) => {
        //         //     if (member?.peerid === data?.peerid) {
        //         //       return data;
        //         //     }
        //         //     return member;
        //         //   });
        //         //   setVoiceMembers(newVoiceMembers);
        //         // } else {
        //         //   voiceMembers.push(data);
        //         //   setVoiceMembers(voiceMembers);
        //         // }
        //       });
        //     }
        //   });
        // });

        setVoiceConnected(true);
        setLocalVoiceStream(localStream);
        changeInVoiceStatus(props.webSocket, roomID, uuid, "in");
        getTeamStatus(roomID, teamName, uuid).then((results) => {
          const newMembers = results.map((result) => {
            if (localStream && result.inVoice === "in") {
              const newPeerCall = peerRef.current?.call(
                result.uuid,
                localStream,
                { metadata: { teamName: teamName } }
              );
              const newDataConn = peerRef.current?.connect(result.uuid);
              result.mediaConnection = newPeerCall;
              result.dataConnection = newDataConn;
              return result;
            }
            return undefined;
          });
          setChatMembers([...newMembers]);
          setDataMembers([...newMembers]);
        });
        setSelfChannel({
          deviceId: inputSelected,
          speaking: false,
          volume: 1,
        });
      });
  };

  useEffect(() => {
    if (callMember) {
      let hasMember = false;
      const filterMembers = chatMembers.map((chatMember) => {
        if (chatMember) {
          if (chatMember.uuid !== callMember.uuid) {
            return chatMember;
          } else {
            hasMember = true;
            chatMember.mediaConnection = callMember.mediaConnection;
            return chatMember;
          }
        }
        return undefined;
      });
      if (hasMember) {
        setChatMembers([...filterMembers]);
      } else {
        setChatMembers([...filterMembers, callMember]);
      }
      hasMember = false;
      setCallMember(undefined);
    }
  }, [callMember, chatMembers]);

  // useEffect(() => {
  //   if (dataMember) {
  //     const filterMembers = dataMembers.filter(
  //       (chatMember) => chatMember.uuid !== dataMember.uuid
  //     );
  //     setDataMembers([...filterMembers, dataMember]);
  //     setDataMember(undefined);
  //   }
  // }, [dataMember, dataMembers]);

  useEffect(() => {
    changeInVoiceStatus(props.webSocket, roomID, uuid, "out");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 機器の更新処理
  const refreshDevices = useCallback(async () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audioInputs = devices.filter(
        (device) => device.kind === "audioinput"
      );
      setInputDevices(audioInputs);
      const audioOutputs = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      setOutputDevices(audioOutputs);
    });
  }, []);

  useEffect(() => {
    // 初回(=componentDidMount)で最新の機器一覧を取得
    refreshDevices();
    // 以降は機器の着脱が行われるタイミングで更新処理を実施
    navigator.mediaDevices.addEventListener("devicechange", refreshDevices);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        refreshDevices
      );
    };
  }, [refreshDevices]);

  useEffect(() => {
    localVoiceStream?.getAudioTracks().forEach((track) => {
      track.stop();
      track.enabled = false;
    });
    chatMembers.forEach((chatMember) => {
      chatMember?.mediaConnection?.close();
    });
    setChatMembers([]);
    setLocalVoiceStream(null);
    setVoiceConnected(false);
    changeInVoiceStatus(props.webSocket, uuid, roomID, "out");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamName]);

  useEffect(() => {
    if (peerRef.current) return;
    const newPeer = new Peer(uuid, {
      host: psURL,
      port: Number(psPort),
      path: psPath,
      config: {
        iceServers: [
          {
            urls: stunURL + ":" + port,
          },
          {
            urls: turnURL + ":" + port,
            username: "username",
            credential: "password",
          },
        ],
      },
      secure: true,
    });
    peerRef.current = newPeer;

    peerRef.current?.on("open", () => {
      console.log("open");
    });

    peerRef.current?.on("disconnected", () => {
      console.log("disconnected");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.voiceChat}>
      <div className={styles.voiceChatLeft}>
        {!voiceConnected && (
          <JoinButton
            isDisabled={!isInTeam}
            isLoading={false}
            onClick={() => {
              joinVoiceChat();
            }}
          />
        )}
        {voiceConnected && (
          <>
            <div className={classnames(styles.voiceChatMembers)}>
              <LocalAudioContent
                localVoiceStream={localVoiceStream}
                micMuted={micMuted}
                memberName={userName}
                inputSelected={inputSelected}
              />
              {chatMembers.map((member) => {
                if (
                  member?.inVoice === "out" ||
                  !member?.mediaConnection ||
                  !member?.mediaConnection?.peerConnection ||
                  !member?.mediaConnection?.peerConnection.connectionState ||
                  member?.mediaConnection.peerConnection.connectionState ===
                    "closed" ||
                  member?.mediaConnection.peerConnection.connectionState ===
                    "disconnected"
                ) {
                  return <></>;
                }
                return (
                  <RemoteAudioContent
                    mediaConnection={member.mediaConnection}
                    memberUUID={member.uuid}
                    memberName={member.name}
                    headPhoneMuted={headPhoneMuted}
                    micMuted={micMuted}
                    volume={selfChannel ? selfChannel.volume : 1}
                    inputSelected={inputSelected}
                    outputSelected={outputSelected}
                    localVoiceStream={localVoiceStream}
                    isInVoice={member.inVoice}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      <div className={styles.voiceChatRight}>
        <span
          className={classnames("material-symbols-rounded", {
            [styles.mic]: true,
            [styles.disabled]: !voiceConnected,
          })}
          onClick={() => {
            if (voiceConnected) {
              if (localVoiceStream) {
                localVoiceStream.getAudioTracks()[0].enabled = micMuted;
              }
              chatMembers.forEach((chatMember) => {
                chatMember?.dataConnection?.send({
                  mute: !micMuted,
                  uuid: chatMember.uuid,
                });
              });
              setMicMuted(!micMuted);
            }
          }}
        >
          {micMuted ? "mic_off" : "mic"}
        </span>
        <span
          className={classnames("material-symbols-rounded", {
            [styles.headphone]: true,
            [styles.disabled]: !voiceConnected,
          })}
          onClick={() => {
            if (voiceConnected) {
              setHeadPhoneMuted(!headPhoneMuted);
            }
          }}
        >
          {headPhoneMuted ? "volume_off" : "volume_up"}
        </span>
        <span
          className={classnames("material-symbols-rounded", {
            [styles.callEnd]: true,
            [styles.disabled]: !voiceConnected,
          })}
          onClick={() => {
            if (voiceConnected) {
              localVoiceStream?.getAudioTracks().forEach((track) => {
                track.stop();
                track.enabled = false;
              });
              chatMembers.forEach((chatMember) => {
                chatMember?.mediaConnection?.close();
              });
              setChatMembers([]);
              setLocalVoiceStream(null);
              setVoiceConnected(false);
              changeInVoiceStatus(props.webSocket, uuid, roomID, "out");
            }
          }}
        >
          call_end
        </span>
        <span
          className={classnames("material-symbols-rounded", styles.settings)}
          onClick={() => {
            setOpenVoiceSettings(!openVoiceSettings);
          }}
        >
          settings
        </span>
        {openVoiceSettings && (
          <VoiceSettingsModal
            inputDevices={inputDevices}
            outputDevices={outputDevices}
            inputSelected={inputSelected}
            outputSelected={outputSelected}
            volume={volume}
            onChange={(value) => {
              setSelfChannel({
                ...selfChannel,
                volume: volume / 100,
              });
              setHeadPhoneMuted(value === 0);
              setVolume(value);
            }}
            clickOutSide={() => {
              setOpenVoiceSettings(!openVoiceSettings);
            }}
            switchOutputDevice={(deviceId) => {
              setOutputSelected(deviceId);
            }}
            switchInputDevice={(deviceId) => {
              if (!voiceConnected) {
                setInputSelected(deviceId);
                return;
              }
              navigator.mediaDevices
                .getUserMedia({ video: false, audio: { deviceId: deviceId } })
                .then((stream) => {
                  refreshDevices();
                  localVoiceStream?.getAudioTracks().forEach((track) => {
                    track.stop();
                    track.enabled = false;
                  });
                  setLocalVoiceStream(stream);
                  const selfSpeechEvents = hark(stream);
                  selfSpeechEvents.on("speaking", function () {
                    setSelfChannel({
                      ...selfChannel,
                      speaking: true,
                    });
                  });
                  selfSpeechEvents.on("stopped_speaking", function () {
                    setSelfChannel({
                      ...selfChannel,
                      speaking: false,
                    });
                  });
                  setLocalVoiceStream(stream);
                  setInputSelected(deviceId);
                });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VoiceChat;
